.footer-payments-confidence {
  @extend %u-list-unstyled;
  @extend %u-flex-row-xcenter-ycenter;
  margin-bottom: 0;
  flex-wrap: wrap;
  padding: $footer-content-padding-mobile * 2 0;

  @include respond-to-up($footer-accordion-breakpoint) {
    padding: $footer-content-padding-desktop 0 $footer-content-padding-desktop /
      3;
  }

  > li {
    @include size($footer-payments-confidence-icon-size-mobile, auto);
    margin: $footer-payments-confidence-padding / 2;

    @include respond-to-up(lg) {
      @include size($footer-payments-confidence-icon-size-desktop, auto);
    }
  }
}
