@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.block-minicart .action.close > span, .customer-welcome .switch > span, .action.nav-toggle > span, .form.minisearch .label > span, .form.minisearch > .actions .action.search > span, .minicart-wrapper .showcart .counter-label,
.minicart-wrapper .showcart .text, .cms-home .page-title-wrapper, .footer-social-links > li > a > span {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.greet.welcome:empty, .block-search .block-title, .search-autocomplete > ul[role="listbox"], .minicart-wrapper .block-minicart .block-title strong {
  display: none !important;
}

.header-panel__block,
.header.links, .homepage-usp-block ul, .navigation ul, .footer.links, .footer.links ul, .footer-social-links, .footer-payments-confidence {
  padding-left: 0;
  list-style: none;
}

.header.panel, .header.panel > .header > .authorization-link, .welcome__message, .header.content, .homepage-usp-block ul, .nav-sections-item-title, .navigation .category-back-link > a:before, .form.minisearch .label, .form.minisearch > .actions, .block-minicart.empty, .footer.links, .footer-social-links, .footer-payments-confidence {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

.header-panel__block,
.header.links, .footer .newsletter.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.newsletter.block .action.subscribe {
  background: #b0aa00;
  color: #fff;
  text-transform: uppercase;
  font-weight: 800;
  padding: 26px 54px;
}

.newsletter.block .action.subscribe:focus, .newsletter.block .action.subscribe:hover {
  color: #fff;
  background: #fff831;
}

.minicart-wrapper:after {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  content: '';
  background: rgba(0, 0, 0, 0.58);
  opacity: 1;
  pointer-events: auto;
  transition: opacity 0.195s linear;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  z-index: 1025;
}

.block-minicart .action.close {
  width: 16px;
  height: 16px;
  padding: 1rem;
}

.block-minicart .action.close:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.block-minicart .action.close:before {
  width: 16px;
  height: 16px;
  font-size: 16px;
}

.block-minicart .action.close:focus {
  outline: none;
}

.footer.links .footer-nav-heading {
  position: relative;
}

.footer.links .footer-nav-heading:before {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.header.panel {
  font-size: 0.75rem;
}

.footer .newsletter.block .title, .footer.links .footer-nav-heading {
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1;
}

.action.nav-toggle, .nav-sections-item-title, .navigation li[class^="level"] a, .navigation li[class*=" level"] a, .form.minisearch .label, .autocomplete-list [role="option"] {
  transition: background-color 0.225s cubic-bezier(0, 0, 0.2, 1);
  will-change: background-color;
  backface-visibility: hidden;
}

.homepage-promo-item__content, .navigation > ul, .minicart-wrapper .block-minicart, .footer.links .footer-nav-heading:before {
  transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.homepage-promo-item__media picture > img, .navigation .all-category, .sections.nav-sections, .action.showcart,
.field.search > .label, .footer-social-links > li > a:after {
  transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1), opacity 0.375s cubic-bezier(0, 0, 0.2, 1), visibility 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.footer.links .heading:not(:first-child) > ul {
  transition: max-height 0.375s cubic-bezier(0, 0, 0.2, 1), visibility 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: max-height;
  backface-visibility: hidden;
}

.footer .newsletter.block .title {
  margin-bottom: 1.5rem;
}

@media screen and (min-width: 992px) {
  .footer .newsletter.block .title {
    margin-bottom: 3rem;
  }
}

.footer .newsletter.block {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

@media screen and (min-width: 992px) {
  .footer .newsletter.block {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

/**
 * Site logo
 */
.header .logo {
  width: auto;
  height: 28px;
  display: block;
}

@media screen and (min-width: 992px) {
  .header .logo {
    height: auto;
  }
}

.header .logo > img {
  width: auto;
  height: 100%;
}

@media screen and (min-width: 992px) {
  .header .logo > img {
    width: auto;
    height: auto;
  }
}

/**
 * Panel Header Styles
 */
.header.panel {
  width: 100%;
  height: 39px;
  justify-content: flex-end;
  background: #F2F2F2;
  padding: 0 1rem;
  display: none;
}

@media screen and (min-width: 992px) {
  .header.panel {
    display: flex;
  }
}

.page-header > .panel.wrapper {
  background: #F2F2F2;
}

.header-panel__block,
.header.links {
  margin-bottom: 0;
}

.header-panel__block a,
.header.links a {
  display: inline-block;
}

.header.panel .header-panel__block, .header.panel
.header.links {
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  text-transform: uppercase;
}

.header.panel .header-panel__block {
  flex: 1 1 auto;
  justify-content: flex-start;
}

.header.panel .header-panel__block > p:last-of-type {
  margin-bottom: 0;
}

.header.panel > .header {
  height: 100%;
}

.header.panel > .header > .authorization-link:after {
  content: attr(data-label);
  margin: 0 1rem;
}

.header.panel > .header > .authorization-link:before,
.welcome__message:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
  margin-right: 1rem;
}

.welcome__message {
  padding-left: 0.5rem;
}

@media screen and (max-width: 991.98px) {
  .welcome__message {
    padding: 0.5rem 0;
    background: #e9ecef;
    font-weight: 800;
  }
}

@media screen and (max-width: 991.98px) {
  .welcome__message:before {
    display: none;
  }
}

/**
 * Main Header Wrapper
 *
 */
.header.content {
  position: relative;
  justify-content: flex-start;
  background: #FFF;
  color: #000;
  padding: 0 1rem;
  min-height: 44px;
  overflow-x: hidden;
}

@media screen and (min-width: 992px) {
  .header.content {
    min-height: 100px;
    overflow: visible;
  }
}

.header.content .minicart-wrapper {
  order: 3;
}

.header.content .block-search {
  margin-left: auto;
  margin-right: 0.25rem;
  order: 2;
}

@media screen and (min-width: 992px) {
  .header.content .block-search {
    margin-right: 1rem;
  }
}

.page-header {
  position: relative;
  z-index: 1030;
}

@media screen and (min-width: 992px) {
  .page-header {
    background: #FFF;
  }
}

#cookie-status {
  display: none;
}

/**
 * Language Switcher
 */
.switcher {
  text-transform: uppercase;
  margin: 1rem 1rem;
}

.switcher .options {
  display: inline-block;
  position: relative;
}

.switcher .options::after {
  display: block;
  clear: both;
  content: "";
}

.switcher .options ul.dropdown {
  position: absolute;
  z-index: 1060;
  display: none;
  width: 100%;
  margin-top: 10px;
  padding: 0;
  border: 1px solid #adb5bd;
  background: #fff;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
  top: auto;
  left: 0;
}

.switcher .options ul.dropdown:after, .switcher .options ul.dropdown:before {
  position: absolute;
  content: '';
  transform: scaleX(1.4);
}

.switcher .options ul.dropdown:before {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 10.5px solid #fff;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  z-index: 3;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -10px;
}

.switcher .options ul.dropdown:after {
  width: 0;
  height: 0;
  content: '';
  z-index: 2;
  border-bottom: 10.5px solid #adb5bd;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  transform: scaleX(1.4) translateY(1px);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: -11px;
}

.switcher .options ul.dropdown > li {
  padding: 0.5em;
}

.switcher .options ul.dropdown > li:focus, .switcher .options ul.dropdown > li:hover {
  background-color: #dddcdb;
  cursor: pointer;
}

.switcher .options ul.dropdown > li:first-child {
  margin-top: 1px;
}

.switcher .options.active {
  overflow: visible;
}

.switcher .options.active ul.dropdown {
  display: block;
}

.switcher .options .action.toggle {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  -webkit-appearance: none;
  white-space: normal;
  padding-right: 9px;
}

.switcher .options .action.toggle:after {
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  content: "";
  font-family: "ms-icons";
  font-size: 10px;
  line-height: 20px;
  transform-origin: 50% 50%;
  transform-origin: 10px 10px;
  transform: rotate(0deg) translateX(0) translateY(0);
  transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1);
  will-change: transform;
  backface-visibility: hidden;
}

.switcher .options.active .action.toggle {
  background: #e9ecef;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.switcher .options.active .action.toggle:after {
  transform: rotate(180deg) translateX(0) translateY(0);
}

.switcher .options ul.dropdown {
  animation: u-animation-slide-down-small 0.195s cubic-bezier(0.4, 0, 0.6, 1);
  animation-delay: 0.02s;
  animation-fill-mode: both;
}

.switcher .label {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.switcher .switcher-trigger {
  padding: 1rem;
}

.switcher .switcher-trigger:hover, .switcher .switcher-trigger:focus {
  outline: 0 none;
}

.switcher .switcher-option a {
  display: block;
  text-align: center;
  text-decoration: none;
}

.switcher-trigger,
.switcher-options,
.switcher {
  height: 100%;
}

@media screen and (min-width: 992px) {
  .header.panel .switcher-language {
    margin-top: 0;
    margin-bottom: 0;
  }
}

@media screen and (max-width: 991.98px) {
  .switcher-language.switcher .options {
    width: 100%;
  }
}

@media screen and (max-width: 991.98px) {
  .switcher-language.switcher .options .switcher-trigger {
    justify-content: space-between;
  }
}

@media screen and (max-width: 991.98px) {
  .switcher-language.switcher .options.active ul.dropdown {
    margin-top: 0;
  }
}

@media screen and (max-width: 991.98px) {
  .switcher-language.switcher .options.active ul.dropdown:before, .switcher-language.switcher .options.active ul.dropdown:after {
    display: none;
  }
}

.switcher-language .view-default {
  display: block;
  font-weight: normal;
}

/**
 * Header Customer Menu Dropdown Styles
 */
.header.panel .customer-welcome + .authorization-link,
.nav-sections-item-content .customer-welcome + .authorization-link {
  display: none;
}

@media screen and (min-width: 992px) {
  .customer-welcome {
    display: inline-block;
    position: relative;
    height: 100%;
    vertical-align: middle;
    padding-left: 0.5rem;
  }
  .customer-welcome::after {
    display: block;
    clear: both;
    content: "";
  }
  .customer-welcome .customer-menu {
    position: absolute;
    z-index: 1060;
    display: none;
    width: 100%;
    margin-top: 10px;
    padding: 0;
    border: 1px solid #adb5bd;
    background: #fff;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.15);
    top: auto;
    left: 0;
  }
  .customer-welcome .customer-menu:after, .customer-welcome .customer-menu:before {
    position: absolute;
    content: '';
    transform: scaleX(1.4);
  }
  .customer-welcome .customer-menu:before {
    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-bottom: 10.5px solid #fff;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    z-index: 3;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -10px;
  }
  .customer-welcome .customer-menu:after {
    width: 0;
    height: 0;
    content: '';
    z-index: 2;
    border-bottom: 10.5px solid #adb5bd;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    transform: scaleX(1.4) translateY(1px);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -11px;
  }
  .customer-welcome .customer-menu > li {
    padding: 0.5em;
  }
  .customer-welcome .customer-menu > li:focus, .customer-welcome .customer-menu > li:hover {
    background-color: #dddcdb;
    cursor: pointer;
  }
  .customer-welcome .customer-menu > li:first-child {
    margin-top: 1px;
  }
  .customer-welcome.active {
    overflow: visible;
  }
  .customer-welcome.active ul.dropdown {
    display: block;
  }
  .customer-welcome span.customer-name {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    -webkit-appearance: none;
    white-space: normal;
    padding-right: 9px;
  }
  .customer-welcome span.customer-name:after {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    content: "";
    font-family: "ms-icons";
    font-size: 10px;
    line-height: 20px;
    transform-origin: 50% 50%;
    transform-origin: 10px 10px;
    transform: rotate(0deg) translateX(0) translateY(0);
    transition: transform 0.375s cubic-bezier(0, 0, 0.2, 1);
    will-change: transform;
    backface-visibility: hidden;
  }
  .customer-welcome.active span.customer-name {
    background: #e9ecef;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .customer-welcome.active span.customer-name:after {
    transform: rotate(180deg) translateX(0) translateY(0);
  }
}

@media screen and (min-width: 992px) {
  .customer-welcome .customer-menu {
    animation: u-animation-slide-down-small 0.195s cubic-bezier(0.4, 0, 0.6, 1);
    animation-delay: 0.02s;
    animation-fill-mode: both;
    left: auto;
    right: 0;
    min-width: 158px;
  }
}

.customer-welcome .customer-name {
  display: none;
}

@media screen and (min-width: 992px) {
  .customer-welcome .customer-name {
    height: 100%;
    padding-left: 0;
    padding-right: 0 !important;
    line-height: 1;
  }
}

.customer-welcome .customer-name:focus {
  outline: none;
}

.customer-welcome.active .customer-menu {
  display: block;
}

.customer-welcome .switch {
  padding: 0;
}

.header.panel .customer-welcome .header.links {
  display: block;
  width: 100%;
  text-align: center;
}

.customer-welcome .customer-menu > ul > li {
  padding: 0.5em;
}

@media screen and (max-width: 991.98px) {
  .customer-welcome .customer-menu > ul > li {
    border-bottom: 1px solid #eee !important;
  }
}

.customer-welcome .customer-menu > ul > li:focus, .customer-welcome .customer-menu > ul > li:hover {
  background-color: #dddcdb;
  cursor: pointer;
}

.customer-welcome .customer-menu > ul > li:first-child {
  margin-bottom: 1px;
}

.homepage-usp-block {
  position: relative;
  width: 100vw;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  color: #4a4a4a;
  background: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  height: 40px;
}

@media screen and (min-width: 992px) {
  .homepage-usp-block {
    height: 45px;
  }
}

.homepage-usp-block ul {
  max-width: 1310px;
  margin: 0 auto;
  height: 100%;
  padding: 0;
  justify-content: space-around;
  font-size: 80%;
  letter-spacing: 0.02em;
  flex-wrap: wrap;
  overflow: hidden;
}

.homepage-usp-block ul > li {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 0.25rem;
  margin: 0;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .homepage-usp-block ul > li {
    width: auto;
    height: 45px;
  }
}

@media screen and (min-width: 992px) {
  .homepage-usp-block ul {
    flex-wrap: nowrap;
  }
}

.homepage-usp-block .slick-slide {
  text-align: center;
}

.homepage-slider-container .homepage-usp-block {
  position: absolute;
  z-index: 2;
}

.homepage-promo-block {
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: repeat(2, minmax(120px, 1fr));
  grid-auto-flow: dense;
  margin: 1.5em 0;
}

@media screen and (min-width: 992px) {
  .homepage-promo-block {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.homepage-promo-block > .homepage-promo-item:nth-child(1) {
  display: none;
  grid-row-end: span 2;
  grid-column-end: span 2;
}

@media screen and (min-width: 430px) {
  .homepage-promo-block > .homepage-promo-item:nth-child(1) {
    display: block;
    grid-row-end: span 2;
    grid-column-end: span 1;
  }
}

.homepage-promo-block > .homepage-promo-item:nth-child(5), .homepage-promo-block > .homepage-promo-item:nth-child(2) {
  grid-column-end: span 2;
  grid-row-end: span 1;
}

@media screen and (min-width: 430px) {
  .homepage-promo-block > .homepage-promo-item:nth-child(5), .homepage-promo-block > .homepage-promo-item:nth-child(2) {
    grid-column-end: span 1;
  }
}

.homepage-promo-block > .homepage-promo-item:nth-child(3) {
  grid-column-end: span 2;
}

.homepage-promo-block > .homepage-promo-item:nth-child(4) {
  grid-column-end: span 2;
}

.homepage-promo-item {
  position: relative;
  width: 100%;
  cursor: pointer;
  background: #e9ecef;
}

.homepage-promo-item:hover .homepage-promo-item__media img {
  transform: scale(1.1) translateX(2%);
  opacity: 0.8;
}

.homepage-promo-item:hover .homepage-promo-item__content {
  transform: translateY(-2%);
}

.homepage-promo-item:hover .homepage-promo-item__content h4 {
  background: #fff;
}

.homepage-promo-item__media {
  overflow: hidden;
  height: 100%;
}

.homepage-promo-item__media picture > img {
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
  transform: scale(1);
  display: block;
  height: 100%;
  width: 100%;
  opacity: 1.0;
}

.homepage-promo-item__content {
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5em;
  text-align: center;
  transform: translateY(0);
}

@media screen and (min-width: 992px) {
  .homepage-promo-item__content {
    padding: 20%;
  }
}

.homepage-promo-item__content__inner {
  display: inline-block;
  color: #000;
  text-align: center;
}

.homepage-promo-item__content__inner h4 {
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-transform: uppercase;
  letter-spacing: -0.02em;
  font-weight: 800;
  line-height: 1.0;
}

.homepage-promo-item__content__inner h4 {
  font-size: 20px;
}

@media screen and (min-width: 0px) {
  .homepage-promo-item__content__inner h4 {
    font-size: calc(20px + 19 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .homepage-promo-item__content__inner h4 {
    font-size: 39px;
  }
}

.homepage-promo-item__content.homepage-promo-item__content--align-cl {
  justify-content: flex-start;
  text-align: left;
}

.homepage-promo-item__content.homepage-promo-item__content--align-cr {
  justify-content: flex-end;
  text-align: right;
}

.homepage-promo-item__content.homepage-promo-item__content--align-tl {
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
}

.homepage-promo-item__content.homepage-promo-item__content--align-tr {
  align-items: flex-start;
  justify-content: flex-end;
  text-align: right;
}

.homepage-promo-item__content.homepage-promo-item__content--align-bl {
  align-items: flex-end;
  justify-content: flex-start;
  text-align: left;
}

.homepage-promo-item__content.homepage-promo-item__content--align-br {
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
}

/**
 * Nav Hamburger Menu
 */
.action.nav-toggle {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-right: 1rem;
  padding: 0;
}

.action.nav-toggle:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (min-width: 992px) {
  .action.nav-toggle {
    display: none;
  }
}

.action.nav-toggle:before {
  text-align: center;
}

.nav-open .action.nav-toggle {
  background: #000;
  overflow: hidden;
}

.nav-open .action.nav-toggle:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 26px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.nav-open .action.nav-toggle:before {
  animation: icon-swap-slide 0.375s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-fill-mode: both;
}

@keyframes icon-swap-slide {
  0%, 20% {
    opacity: 0;
    transform: translateY(50%);
  }
  75%, 100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes icon-swap-slide-x {
  0%, 20% {
    opacity: 0;
    transform: translateX(-100%);
  }
  75%, 100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes icon-swap-slide-x-r {
  0%, 20% {
    opacity: 0;
    transform: translateX(100%);
  }
  75%, 100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

/**
 * Mobile Navigation sections/tabs
 */
.nav-sections {
  position: fixed;
  top: 42px;
  right: 0;
  width: 100%;
  height: calc(100vh - 42px);
  background: #000;
}

@media screen and (max-width: 991.98px) {
  .nav-sections {
    z-index: 1030;
  }
}

@media screen and (min-width: 992px) {
  .nav-sections {
    position: relative;
    top: -1px;
    right: 0;
    z-index: 5;
    height: auto;
    background: #000;
  }
}

.nav-sections-items {
  width: 100%;
  height: 100%;
  display: block;
}

@media screen and (min-width: 992px) {
  .nav-sections-items {
    padding: 0 1rem;
  }
}

.nav-sections-item-title {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  width: 140px;
  height: 78px;
  color: #6c757d;
  background: #000;
  max-height: none;
}

@media screen and (min-width: 992px) {
  .nav-sections-item-title {
    display: none;
  }
}

.nav-sections-item-title:focus {
  outline: 0 none;
}

.nav-sections-item-title > a {
  color: #6c757d;
}

.nav-sections-item-title > a:hover {
  color: #6c757d;
  text-decoration: none;
}

.nav-sections-item-title > a:focus {
  outline: 0 none;
}

.nav-sections-item-title[aria-controls="store.links"] {
  left: 140px;
}

.nav-sections-item-title[aria-controls="store.links"]:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (max-width: 430px) {
  .nav-sections-item-title[aria-controls="store.links"] {
    width: 78px;
  }
}

@media screen and (min-width: 430px) {
  .nav-sections-item-title[aria-controls="store.links"]:before {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .nav-sections-item-title[aria-controls="store.links"] .nav-sections-item-switch {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
}

@media screen and (min-width: 992px) {
  .nav-sections-item-title[aria-controls="store.links"] {
    display: none;
  }
}

.nav-sections-item-title[aria-controls="store.settings"] {
  left: 280px;
}

.nav-sections-item-title[aria-controls="store.settings"]:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (max-width: 430px) {
  .nav-sections-item-title[aria-controls="store.settings"] {
    left: 218px;
    width: 78px;
  }
}

@media screen and (min-width: 430px) {
  .nav-sections-item-title[aria-controls="store.settings"]:before {
    display: none;
  }
}

@media screen and (max-width: 430px) {
  .nav-sections-item-title[aria-controls="store.settings"] .nav-sections-item-switch {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    clip-path: inset(50%);
    border: 0;
  }
}

@media screen and (min-width: 992px) {
  .nav-sections-item-title[aria-controls="store.settings"] {
    display: none;
  }
}

.nav-sections-item-title.active {
  background: #fff;
}

.nav-sections-item-title.active > a {
  color: #333333;
}

.nav-sections-item-content {
  padding-top: 78px;
}

@media screen and (max-width: 991.98px) {
  .nav-sections-item-content {
    height: calc(100vh - 88px);
  }
}

@media screen and (min-width: 992px) {
  .nav-sections-item-content {
    padding-top: 0;
  }
}

.nav-sections-item-content .switcher-language,
.nav-sections-item-content .navigation,
.nav-sections-item-content .header.links {
  background: #fff;
}

@media screen and (min-width: 992px) {
  #store\.links,
  #store\.settings {
    display: none !important;
  }
}

@media screen and (max-width: 991.98px) {
  .customer-welcome .customer-menu--account {
    -webkit-overflow-scrolling: touch;
  }
}

/**
   * Site navigation
   */
.navigation li[class^="level"],
.navigation li[class*=" level"] {
  border-bottom: 1px solid #eee;
}

@media screen and (min-width: 992px) {
  .navigation li[class^="level"],
  .navigation li[class*=" level"] {
    border-bottom: 0 none;
  }
}

.navigation li[class^="level"] a, .navigation li[class*=" level"] a {
  display: flex;
  width: 100%;
  padding: 1.5rem;
  text-decoration: none;
  color: #000;
  background: transparent;
}

@media screen and (min-width: 992px) {
  .navigation li[class^="level"] a, .navigation li[class*=" level"] a {
    background: #000;
    color: #fff;
    padding: 1rem;
  }
}

.navigation li[class^="level"] > a, .navigation li[class*=" level"] > a {
  position: relative;
}

@media screen and (min-width: 992px) {
  .navigation li[class^="level"] > a:hover, .navigation li[class*=" level"] > a:hover, .navigation li[class^="level"] > a:focus, .navigation li[class*=" level"] > a:focus {
    background-color: #000;
    color: #fff;
  }
}

@media screen and (min-width: 992px) {
  .navigation li.level0[class^="level"] > a, .navigation li.level0[class*=" level"] > a {
    height: 56px;
  }
}

@media screen and (min-width: 992px) {
  .navigation li.level1[class^="level"] a, .navigation li.level1[class*=" level"] a {
    padding: 0.5rem 1.5rem;
  }
}

@media screen and (min-width: 992px) {
  .navigation li.level2[class^="level"] a, .navigation li.level2[class*=" level"] a {
    padding: 0.5rem 1.5rem;
  }
}

.navigation {
  width: 100%;
  overflow: hidden;
}

@media screen and (min-width: 992px) {
  .navigation {
    overflow: visible;
  }
}

@media screen and (min-width: 992px) {
  .navigation li[class^="level"] .submenu a,
  .navigation li[class*=" level"] .submenu a {
    background: #fff;
    color: #000;
  }
}

@media screen and (min-width: 992px) {
  .navigation .submenu {
    display: none;
  }
}

.navigation .submenu.level1, .navigation .submenu.level0 {
  position: absolute !important;
}

@media screen and (max-width: 991.98px) {
  .navigation .submenu.level1, .navigation .submenu.level0 {
    top: 0 !important;
    left: 100% !important;
    width: 100%;
    flex-direction: column;
  }
}

@media screen and (min-width: 992px) {
  .navigation .submenu.level0 {
    left: 0 !important;
    width: 100%;
    background: #fff;
    color: #000;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 1rem 1.5rem 1.5rem;
  }
}

@media screen and (min-width: 992px) {
  .navigation .submenu.level0 > li.parent {
    min-width: 350px;
  }
}

@media screen and (min-width: 992px) {
  .navigation .submenu.level1 {
    display: block !important;
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }
}

@media screen and (min-width: 992px) {
  .navigation a.ui-state-active + .submenu.level0 {
    display: flex !important;
  }
}

@media screen and (min-width: 992px) {
  .navigation a.ui-state-active + .submenu.level0 .all-category {
    transform: translateY(0%);
    opacity: 1;
  }
}

.navigation .ui-menu-icon.ui-icon {
  position: absolute;
  top: 0;
  right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navigation .ui-menu-icon.ui-icon:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (min-width: 992px) {
  .navigation .ui-menu-icon.ui-icon {
    display: none;
  }
}

.navigation .all-category > a:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.navigation .all-category > a:before {
  order: 2;
  margin-left: 1rem;
}

.navigation .category-back-link a,
.navigation .all-category a {
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

@media screen and (min-width: 992px) {
  .navigation .all-category {
    align-self: flex-end;
    margin-left: auto;
    transition-delay: 0.23s;
    transform: translateY(20%);
    opacity: 0;
  }
}

@media screen and (min-width: 992px) {
  .navigation .all-category a {
    width: auto;
    height: auto;
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border: 1px solid #000;
  }
}

.navigation .all-category a:hover, .navigation .all-category a:focus {
  background-color: #000 !important;
  color: #fff !important;
}

@media screen and (min-width: 992px) {
  .navigation .category-back-link {
    display: none;
  }
}

.navigation .category-back-link > a {
  position: relative;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  background: #767676 !important;
}

.navigation .category-back-link > a:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.navigation .category-back-link > a:before {
  position: absolute;
  top: 0;
  left: 1.5rem;
  height: 100%;
}

.navigation > ul {
  margin-bottom: 0;
  transition-delay: 0.15s;
  transform: translateX(0);
}

@media screen and (min-width: 992px) {
  .navigation > ul {
    position: relative;
    display: flex;
    background: #000;
  }
}

.navigation.show-submenu-1 > .ui-menu {
  transform: translateX(-100%);
}

@media screen and (min-width: 992px) {
  .navigation.show-submenu-1 > .ui-menu {
    transform: translateX(0%);
  }
}

.navigation.show-submenu-1 > .ui-menu .submenu .category-back-link > a:before {
  overflow: hidden;
  animation: icon-swap-slide-x-r 0.375s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.4s;
  animation-fill-mode: both;
}

.navigation.show-submenu-1 > .ui-menu .submenu .ui-menu-icon {
  overflow: hidden;
  animation: icon-swap-slide-x 0.375s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.4s;
  animation-fill-mode: both;
}

.navigation.show-submenu-2 > .ui-menu {
  transform: translateX(-200%);
}

.navigation.show-submenu-3 > .ui-menu {
  transform: translateX(-300%);
}

.navigation li.level-top > a.ui-state-active {
  color: #fff !important;
  background: #767676;
}

@media screen and (min-width: 992px) {
  .navigation li.level-top > a.ui-state-active, .navigation li.level-top > a.ui-state-focus {
    background: #fff;
    color: #000 !important;
  }
}

@media screen and (min-width: 992px) {
  .navigation li.level1.parent > a {
    font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: bold;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    font-size: 1.75rem;
  }
}

.nav-sections-item-content .header.links {
  display: block;
}

.nav-sections-item-content .header.links > li {
  border-bottom: 1px solid #eee;
}

.nav-sections-item-content .header.links > li:first-child {
  border-bottom: 0 none;
}

.nav-sections-item-content .header.links a {
  padding: 1.5rem;
  color: #000;
}

.nav-sections-item-content .header.links a:after {
  display: none;
}

.navigation > .ui-menu:focus, .navigation > .ui-menu:active {
  outline: 0 none;
}

.sections.nav-sections {
  transform: translateX(-100%);
  visibility: hidden;
}

@media screen and (min-width: 992px) {
  .sections.nav-sections {
    transform: translateX(0%);
    visibility: visible;
  }
}

@media screen and (max-width: 991.98px) {
  .sections.nav-sections .navigation {
    height: calc(100vh - 88px);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transform: translateZ(0);
    /**
          * Fix mobile safari messing up VH units with auto-hiding navigation bar
          * add a one item padding below to avoid this.
         */
  }
  .ios-safari .sections.nav-sections .navigation {
    height: calc(100vh - 160px);
  }
}

.action.showcart,
.field.search > .label {
  transform: translateY(0%);
  opacity: 1;
  transition-delay: 0.12s;
}

.action.showcart {
  transition-delay: 0s;
}

.nav-open body {
  overflow: hidden;
  height: 100vh;
}

.nav-open .action.showcart,
.nav-open .field.search > .label {
  transform: translateY(-150%);
  opacity: 0;
  visibility: hidden;
  transition-delay: 0;
}

.nav-open .action.showcart {
  transition-delay: 0.12s;
}

.nav-open .page-wrapper > *:not(.nav-sections):not(.page-header) {
  visibility: hidden;
}

.nav-open .sections.nav-sections {
  transform: translateX(0);
  visibility: visible;
}

@media screen and (max-width: 1200px) {
  .ios-safari.nav-open .page-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .navigation .submenu.level0 {
    top: 56px !important;
    animation: navigation-animation 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955);
    animation-fill-mode: both;
    transform-origin: 0% 0%;
    backface-visibility: hidden;
    will-change: transform;
  }
}

@keyframes navigation-animation {
  0%, 20% {
    opacity: 0;
    transform: scaleY(0.95);
  }
  75%, 100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

/**
 * Block Search styles
 */
/**
 * Mini search form styles within header content
 */
@media screen and (min-width: 992px) {
  .form.minisearch {
    position: relative;
  }
}

.form.minisearch .label {
  width: 48px;
  height: 48px;
  margin-bottom: 0;
}

.form.minisearch .label:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (min-width: 992px) {
  .form.minisearch .label {
    display: none;
  }
}

.form.minisearch .label.active {
  background: white;
}

.form.minisearch .control {
  width: 610px;
}

@media screen and (min-width: 768px) {
  .form.minisearch .control {
    width: 610px;
  }
}

@media screen and (min-width: 992px) {
  .form.minisearch .control {
    width: 610px;
  }
}

@media screen and (max-width: 991.98px) {
  .form.minisearch .control {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    padding: 35px 1rem 0;
    background: #eee;
    transform: translateY(0%);
    opacity: 1;
    z-index: 1030;
    transition: opacity 0.195s linear;
  }
}

.form.minisearch .control .minisearch-close-button {
  display: none;
}

.form.minisearch .control .minisearch-close-button:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (max-width: 991.98px) {
  .form.minisearch .control .minisearch-close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 48px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    cursor: pointer;
    font-size: 14px;
  }
}

.form.minisearch .control > input {
  width: 100%;
  padding-left: 1rem;
  height: 46px;
  background: #FFF;
  border: 2px solid #444;
}

@media screen and (min-width: 992px) {
  .form.minisearch .control > input {
    border: 0 none;
    border-left: 2px solid #444;
    height: 46px;
  }
}

.form.minisearch .control > input:focus {
  outline: 0;
}

.form.minisearch > .field {
  position: relative;
  z-index: 1;
  margin-bottom: 0;
}

.form.minisearch > .actions {
  position: absolute;
  top: 0;
  right: 0;
  width: 48px;
  height: 100%;
  background: #FFF;
  z-index: 2;
}

@media screen and (max-width: 991.98px) {
  .form.minisearch > .actions {
    width: 46px;
    height: 46px;
    top: 35px;
    right: calc(1rem + 0.25rem);
    background: transparent;
  }
}

.form.minisearch > .actions .action.search {
  width: 100%;
  height: 100%;
  color: #000;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0;
}

.form.minisearch > .actions .action.search:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

@media screen and (min-width: 992px) {
  .form.minisearch > .actions .action.search {
    border-right: 2px solid #444;
  }
}

@media screen and (max-width: 991.98px) {
  .form.minisearch > .actions .action.search:before:before {
    font-size: 18px;
  }
}

@media screen and (max-width: 991.98px) {
  .form.minisearch:not(.active) .control {
    transform: translateY(-100%);
    opacity: 0;
  }
}

@media screen and (max-width: 991.98px) {
  .form.minisearch:not(.active) > .actions {
    display: none;
  }
}

/**
 * Styles for ElasticSuite Autocomplete search results
 * Default Magento Search Term autocomplete is hidden
 */
@media screen and (max-width: 991.98px) {
  .search-autocomplete {
    width: calc(100% - 2rem) !important;
    margin-top: 2rem;
    max-height: calc(100vh - 160px);
    overflow: auto;
  }
}

@media screen and (max-width: 768px) {
  .search-autocomplete {
    margin-top: 0.8rem;
  }
}

.smile-elasticsuite-autocomplete-result {
  color: #000;
  background: #FFF;
  border: 1px solid rgba(255, 255, 255, 0.15);
}

@media screen and (min-width: 992px) {
  .smile-elasticsuite-autocomplete-result {
    border-top: 0 none;
    border-color: #FFF;
  }
}

.smile-elasticsuite-autocomplete-result > .autocomplete-list {
  margin-bottom: 0.25rem;
}

.smile-elasticsuite-autocomplete-result > .autocomplete-list:last-child {
  margin-bottom: 0;
}

.smile-elasticsuite-autocomplete-result > .autocomplete-list:last-child > dd:last-child {
  margin-bottom: 0;
  border-bottom: 0 none;
}

@media screen and (max-width: 768px) {
  .autocomplete-list {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  .autocomplete-list .price-box .price {
    font-size: 12px;
  }
}

.autocomplete-list > dt,
.autocomplete-list > dd {
  padding: 0.5rem 1rem;
}

.autocomplete-list > dd {
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

@media screen and (min-width: 992px) {
  .autocomplete-list > dd {
    border-color: #FFF;
  }
}

.autocomplete-list [role="option"] {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  background: rgba(238, 238, 238, 0);
  animation: loader-fade 0.375s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-fill-mode: both;
  cursor: pointer;
}

.autocomplete-list [role="option"].selected {
  background: #eeeeee;
}

.autocomplete-list .title-term ~ [role="option"] {
  justify-content: space-between;
}

.autocomplete-list .amount {
  color: #767676;
}

.autocomplete-list .product-image-box {
  padding-right: 1rem;
}

.is-minicart-open .header.content,
.is-search-open .header.content {
  overflow: visible;
}

@media screen and (max-width: 991.98px) {
  .is-minicart-open .header.content,
  .is-search-open .header.content {
    min-height: 0;
  }
}

.category-mini-crumb:not(:empty) + .qs-option-name:before {
  display: none;
}

.category-mini-crumb .qs-option-name:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.category-mini-crumb .qs-option-name:before {
  margin: 0 0.2rem;
  position: relative;
  top: 3px;
}

@keyframes loader-fade {
  0%, 20% {
    opacity: 0;
  }
  75%, 100% {
    opacity: 1;
  }
}

.search-loader {
  display: none;
}

.pac-container {
  z-index: 1290 !important;
}

/**
 * Minicart wrapper styles
 */
.minicart-wrapper {
  position: relative;
}

.minicart-wrapper:after {
  opacity: 0;
  visibility: hidden;
}

.minicart-wrapper.active:after {
  opacity: 1;
  visibility: visible;
}

.minicart-wrapper .showcart {
  position: relative;
}

.minicart-wrapper .showcart.action {
  width: 48px;
  height: 48px;
  position: relative;
  display: inline-block;
  color: #000;
  text-decoration: none;
  text-align: center;
  padding: 0;
}

.minicart-wrapper .showcart.action:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.minicart-wrapper .showcart.action:before {
  position: relative;
  top: 13px;
}

.minicart-wrapper .showcart.action:hover, .minicart-wrapper .showcart.action:focus {
  outline: none;
}

.minicart-wrapper .showcart.action.active {
  background-color: white;
}

.minicart-wrapper .showcart.action .counter {
  position: absolute;
  top: 4px;
  right: auto;
  bottom: auto;
  left: 28px;
  background: #fff;
  color: #000;
  font-size: 12px;
  min-width: 22px;
  min-height: 22px;
  padding: 2px 6px;
  border-radius: 1000px;
  text-align: center;
  width: auto;
}

@media screen and (max-width: 768px) {
  .minicart-wrapper .showcart.action .counter {
    font-size: 10px;
    min-width: 18px;
    margin-left: 4px;
    left: 22px;
  }
}

.minicart-wrapper .showcart.action .counter.empty {
  opacity: 0;
}

/**
 * Minicart Block styles
 */
.block-minicart > div {
  width: 100%;
  height: 100%;
}

.minicart-wrapper .block-minicart {
  position: fixed;
  top: 0;
  right: 0;
  margin: 0;
  padding: 3rem 1.5rem;
  width: calc(100vw - 8rem);
  height: 100vh;
  background: #fff;
  color: #000;
  border: 1px solid #adb5bd;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transform: translateX(100%);
  opacity: 0;
  pointer-events: none;
  z-index: 1030;
}

@media screen and (max-width: 430px) {
  .minicart-wrapper .block-minicart {
    width: 100vw;
  }
}

@media screen and (min-width: 768px) {
  .minicart-wrapper .block-minicart {
    width: calc(640px - 3rem);
  }
}

.minicart-wrapper .block-minicart .action.viewcart {
  margin-top: 1rem;
}

.minicart-wrapper .block-minicart .block-title {
  width: 48px;
  height: 48px;
  position: absolute;
  top: 0.75rem;
  right: auto;
  bottom: auto;
  left: 0.75rem;
}

.minicart-wrapper .block-minicart .block-title:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.minicart-wrapper .block-minicart .block-title:before {
  position: relative;
  top: 11px;
}

.minicart-wrapper .block-minicart .block-content {
  padding-top: 48px;
  margin-bottom: 48px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.minicart-wrapper .block-minicart .block-content::after {
  display: block;
  clear: both;
  content: "";
}

.minicart-wrapper .block-minicart .block-content > .subtotal,
.minicart-wrapper .block-minicart .block-content > .items-total {
  float: left;
  width: 50%;
}

.minicart-wrapper .block-minicart .block-content > .actions {
  clear: both;
  float: none;
  width: 100%;
  margin-bottom: 1rem;
}

.minicart-wrapper .block-minicart .block-content > .actions .secondary {
  max-width: 222px;
  margin: 0 auto;
}

.minicart-wrapper .block-minicart .block-content > .actions .secondary > .action {
  width: 100%;
  margin-bottom: 1rem;
}

.minicart-wrapper .block-minicart .block-content > .actions .secondary > .action:last-child {
  margin-bottom: 0;
}

.minicart-wrapper .block-minicart .block-content > .actions .action {
  margin: 0 auto;
}

.minicart-wrapper .block-minicart .block-content > .subtotal {
  text-align: right;
}

.minicart-wrapper .block-minicart .block-content > .subtotal .price {
  font-size: 1.25rem;
  font-weight: bold;
}

.minicart-wrapper .block-minicart .block-content .subtitle {
  display: none;
}

.minicart-wrapper .block-minicart .block-content .count {
  font-weight: 800;
}

.minicart-wrapper .block-minicart .block-content .minicart-items-wrapper {
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  border-top: 1px solid #adb5bd;
  border-bottom: 1px solid #adb5bd;
  min-height: 130px;
}

@media screen and (max-height: 566px) {
  .minicart-wrapper .block-minicart .block-content .minicart-items-wrapper {
    min-height: 200px;
  }
}

.block-minicart .minicart-items {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: .5rem;
}

.block-minicart .minicart-items > .product-item {
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.block-minicart .minicart-items > .product-item:last-child {
  margin-bottom: 0;
  border-bottom: 0 none;
  padding-bottom: 0;
}

.block-minicart .minicart-items .action.edit {
  display: none;
}

.block-minicart .minicart-items .toggle {
  font-size: 0.875rem;
  color: #767676;
}

.block-minicart .minicart-items .product-item-details {
  position: relative;
}

.block-minicart .minicart-items .product-item-details .product-item-name {
  display: block;
  width: 100%;
  padding-right: 24px;
  margin-bottom: 0.5rem;
}

.block-minicart .minicart-items .product-item-details .action.delete,
.block-minicart .minicart-items .product-item-details .action.edit {
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  z-index: 2;
}

.block-minicart .minicart-items .product-item-details .action.delete > span,
.block-minicart .minicart-items .product-item-details .action.edit > span {
  display: none;
}

.block-minicart .minicart-items .product-item-details .action.delete:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.block-minicart .minicart-items dd,
.block-minicart .minicart-items dt {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.block-minicart .minicart-items dd {
  margin-right: 1rem;
}

.block-minicart .minicart-items .update-cart-item {
  font-size: 0.875rem;
  font-weight: bold;
  margin-left: 0.5rem;
}

@media screen and (max-width: 430px) {
  .block-minicart .minicart-items .update-cart-item {
    margin-left: 0;
    text-align: left;
  }
}

@media screen and (min-width: 430px) {
  .block-minicart .minicart-items .update-cart-item {
    width: auto;
  }
}

.block-minicart .minicart-items .product-image-photo {
  object-fit: contain;
}

.block-minicart .minicart-items .product::after {
  display: block;
  clear: both;
  content: "";
}

.block-minicart .minicart-items .product .product-item-photo {
  width: 78px;
  float: left;
}

.block-minicart .minicart-items .product .product-item-details {
  width: calc(100% - 78px);
  padding-left: 0.5rem;
  float: left;
}

.block-minicart .minicart-items .product .product-item-details .product.actions {
  position: absolute;
  top: 0;
  right: 0;
  display: inline-block;
}

.block-minicart .minicart-items .product-item-pricing::after {
  display: block;
  clear: both;
  content: "";
}

.block-minicart .minicart-items .product-item-pricing .price-container {
  width: 80px;
  float: left;
}

.block-minicart .minicart-items .product-item-pricing .price-container .price-excluding-tax:before,
.block-minicart .minicart-items .product-item-pricing .price-container .price-including-tax:before {
  top: 0;
}

.block-minicart .minicart-items .product-item-pricing .details-qty {
  width: 75px;
  padding-left: 0.5rem;
  float: left;
}

.block-minicart .minicart-items .product-item-pricing .details-qty label {
  font-size: 0.875rem;
  color: #767676;
}

.block-minicart .minicart-items .product-item-pricing .details-qty input {
  width: auto;
  padding: .25rem;
  min-width: 0;
  max-width: 80px;
}

@media screen and (min-width: 768px) {
  .block-minicart .minicart-items .product-item-pricing .details-qty input {
    max-width: 100px;
  }
}

.minicart-wrapper.active .block-minicart {
  transform: translateY(0%);
  opacity: 1;
  pointer-events: auto;
}

.block-minicart.empty {
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 100%;
}

.block-minicart.empty .subtitle.empty {
  display: block;
  text-align: center;
  padding: 0;
  font-weight: normal;
}

.block-minicart .action.close {
  position: absolute;
  top: 0.6rem;
  right: 0;
  cursor: pointer;
}

.minicart-items-wrapper {
  width: 100%;
  height: auto !important;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .block-minicart .minicart-items .product-item-pricing .price-container {
    width: 140px;
    height: 50px;
    line-height: 50px;
  }
}

@media screen and (min-width: 430px) {
  .block-minicart .minicart-items .product-item-pricing .details-qty {
    width: auto;
  }
}

.block-minicart .minicart-items .product-item-pricing .details-qty .price-container .price-wrapper .price-excluding-tax,
.block-minicart .minicart-items .product-item-pricing .details-qty .price-container .price-wrapper .price-including-tax {
  line-height: 1;
}

@media screen and (min-width: 768px) {
  .block-minicart .minicart-items .product-item-pricing .details-qty input.item-qty.cart-item-qty {
    padding: .75rem 1.5rem;
  }
}

.footer .newsletter.block {
  color: #fff;
  background-size: cover;
  padding-left: 3rem;
  padding-right: 3rem;
}

.lazybg .footer .newsletter.block {
  background: url("../images/footer-bg-sm.jpg") top left no-repeat #858585;
}

@media screen and (min-width: 320px) {
  .lazybg .footer .newsletter.block {
    background: url("../images/footer-bg-md.jpg") top left no-repeat #858585;
  }
}

@media screen and (min-width: 992px) {
  .lazybg .footer .newsletter.block {
    background: url("../images/footer-bg-lg.jpg") top left no-repeat #858585;
  }
}

.footer .newsletter.block .title {
  font-size: 1.5rem;
}

.footer .newsletter.block .content {
  text-align: center;
}

.footer .newsletter.block .form {
  position: relative;
}

@media screen and (min-width: 768px) {
  .footer .newsletter.block .form {
    min-width: 460px;
  }
}

.footer .newsletter.block .form button,
.footer .newsletter.block .form input {
  height: 46px;
}

.footer .newsletter.block .form input {
  width: 100%;
}

.footer .newsletter.block .field {
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .footer .newsletter.block .field {
    margin-bottom: 0;
  }
}

.footer .newsletter.block .field input {
  position: relative;
  z-index: 1;
}

@media screen and (min-width: 768px) {
  .footer .newsletter.block .field input {
    padding-right: 166px;
  }
}

.footer .newsletter.block .field + .actions {
  width: 166px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .footer .newsletter.block .field + .actions {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}

.footer .newsletter.block div.mage-error {
  position: relative;
  background-color: rgba(183, 14, 14, 0.9);
  color: white;
  padding: 0.5em;
  z-index: 0;
}

.newsletter.block .action.subscribe {
  box-sizing: border-box;
  width: 100%;
  padding: 0 0.5rem;
}

.newsletter.block .control > label {
  display: none;
}

.footer.content {
  background: #000;
  color: #fff;
  padding-bottom: 2rem;
}

@media screen and (min-width: 768px) {
  .footer.content {
    padding-bottom: 4rem;
  }
}

.footer.links {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 1310px;
  margin: 0 auto;
  padding-top: 2rem;
}

@media screen and (min-width: 768px) {
  .footer.links {
    justify-content: space-between;
    padding-top: 4rem;
  }
}

.footer.links ul {
  height: 100%;
}

.footer.links .footer-nav-heading {
  display: block;
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #fff;
  padding: 1rem 1.5rem;
}

.footer.links .footer-nav-heading:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.footer.links .footer-nav-heading {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .footer.links .footer-nav-heading {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .footer.links .footer-nav-heading {
    font-size: 25px;
  }
}

@media screen and (min-width: 768px) {
  .footer.links .footer-nav-heading {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
  .footer.links .footer-nav-heading {
    font-size: 10px;
  }
}

@media screen and (min-width: 768px) and (min-width: 0px) {
  .footer.links .footer-nav-heading {
    font-size: calc(10px + 10 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 768px) and (min-width: 1440px) {
  .footer.links .footer-nav-heading {
    font-size: 20px;
  }
}

.footer.links .footer-nav-heading:before {
  transition-duration: 0.195s;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
  transition-delay: 0.05s;
  transform-origin: 50% 50%;
  right: 10px;
}

@media screen and (min-width: 768px) {
  .footer.links .footer-nav-heading:before {
    display: none;
  }
}

.footer.links .footer-nav-heading[href] {
  text-decoration: none;
}

.footer.links .footer-logo {
  display: none;
}

@media screen and (min-width: 768px) {
  .footer.links .footer-logo {
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .footer.links .footer-logo {
    margin-right: 4rem;
  }
}

.footer.links .item {
  padding: 0.5rem 1.5rem;
}

@media screen and (min-width: 768px) {
  .footer.links .item {
    padding: 0.5rem 0;
  }
}

.footer.links .item > .widget a,
.footer.links .item > a,
.footer.links .item > span {
  font-size: 0.875rem;
  color: #fff;
}

.footer.links > li {
  width: 100%;
  padding: 0;
}

@media screen and (min-width: 768px) {
  .footer.links > li {
    width: auto;
    flex: 0 1 auto;
    padding: 0.5rem 1.5rem;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.footer.links > li:first-child {
  order: 1;
}

@media screen and (min-width: 768px) {
  .footer.links > li:first-child {
    order: 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199.98px) {
  .footer.links > li:first-child {
    flex: 0 1 100%;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }
}

.footer.links .heading:not(:first-child) > ul {
  max-height: 0;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .footer.links .heading:not(:first-child) > ul {
    max-height: none;
    overflow: visible;
  }
}

@media screen and (min-width: 768px) {
  .footer.links .heading:not(:first-child) > ul > li:last-child {
    padding-bottom: 0;
  }
}

.footer.links .heading:not(:first-child).is-active > ul {
  max-height: 250px;
  overflow: auto;
}

.footer.links .is-active .footer-nav-heading:before {
  content: "";
}

.footer-social-links {
  margin-bottom: 0;
  padding-top: 2rem;
}

@media screen and (min-width: 768px) {
  .footer-social-links {
    justify-content: flex-start;
    padding-top: 0;
  }
}

@media screen and (min-width: 1200px) {
  .footer-social-links {
    padding-top: 4rem;
  }
}

.footer-social-links > li {
  margin: 0.5rem;
}

@media screen and (min-width: 768px) {
  .footer-social-links > li:first-child {
    margin-left: 0;
  }
}

.footer-social-links > li > a {
  width: 34px;
  height: 34px;
  position: relative;
  display: block;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.footer-social-links > li > a:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  content: "";
  transform-origin: 50% 50%;
  transform: scale(0);
  visibility: hidden;
  opacity: 0;
}

.footer-social-links > li > a:hover {
  overflow: visible;
}

.footer-social-links > li > a:hover:after {
  transform: scale(1.1);
  visibility: visible;
  opacity: 1;
}

.footer-social-links__twitter > a {
  background: url("../images/social/twitter.svg");
}

.footer-social-links__twitter > a:after {
  background: url("../images/social/twitter-inverted.svg");
}

.footer-social-links__facebook > a {
  background: url("../images/social/facebook.svg");
}

.footer-social-links__facebook > a:after {
  background: url("../images/social/facebook-inverted.svg");
}

.footer-social-links__youtube > a {
  background: url("../images/social/youtube.svg");
}

.footer-social-links__youtube > a:after {
  background: url("../images/social/youtube-inverted.svg");
}

.footer-social-links__instagram > a {
  background: url("../images/social/instagram.svg");
}

.footer-social-links__instagram > a:after {
  background: url("../images/social/instagram-inverted.svg");
}

.footer-payments-confidence {
  margin-bottom: 0;
  flex-wrap: wrap;
  padding: 4rem 0;
}

@media screen and (min-width: 768px) {
  .footer-payments-confidence {
    padding: 4rem 0 1.33333rem;
  }
}

.footer-payments-confidence > li {
  width: 54px;
  height: auto;
  margin: 0.25rem;
}

@media screen and (min-width: 992px) {
  .footer-payments-confidence > li {
    width: 64px;
    height: auto;
  }
}

.copyright {
  display: block;
  padding: 0 0.5rem 3rem;
  text-align: center;
}

@media screen and (min-width: 768px) {
  .copyright {
    padding-bottom: 0.5rem;
  }
}

.copyright > span {
  display: inline-block;
  padding: 0.5rem;
}

/**
 * Site logo
 */
.header .logo > img {
  height: 28px;
}

@media screen and (min-width: 992px) {
  .header .logo > img {
    height: 55px;
  }
}

@media screen and (min-width: 992px) {
  .header.panel .header.links li.nav.item {
    padding-left: .5rem;
  }
}

/* add override styles to text within the panel header */
.header.panel .header-panel__block, .header.panel
.header.links {
  text-transform: none;
  font-size: 1rem;
}

/* add link color override for panel__block */
.header.panel .header-panel__block a {
  color: #767676;
}

/* add some style and spacing to the or in between login and sign up */
.header.panel > .header > .authorization-link:after {
  margin: 0 6px;
}

.panel.header p {
  margin-bottom: 0;
}

/* search input and cart overrides */
/*
body .form.minisearch .control>input,
body .form.minisearch>.actions .action.search  {
    border: $search-input-border;
    border-radius: 0;
}
body .form.minisearch .control>input {
    font-weight: bold;
    color: $black;

    &::placeholder {
        font-weight: bold;
        color: $black;
    }

    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 20px;
    }
}
body .form.minisearch>.actions .action.search {
    opacity: 1;
    border-left: 0;

    &:before {
        font-weight: bold;
    }

    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        border-right: 0;
    }
}

body .header.content .block-search {
    @include respond-to-up($tweakpoint-nav-toggle) {
        margin-right: 0;
    }
}
*/
@media screen and (min-width: 992px) {
  body .minicart-wrapper .showcart.action {
    background-color: #b0aa00;
    color: #fff;
    border: 2px solid #444;
    border-left: 0;
    height: 46px;
  }
  body .minicart-wrapper .showcart.action:before {
    top: 9px;
  }
}

body .minicart-wrapper .showcart.action .counter {
  background-color: #000;
  color: #fff;
  min-height: 18px;
  padding: 0;
}

@media screen and (min-width: 992px) {
  body .minicart-wrapper .showcart.action .counter {
    min-height: 22px;
  }
}

body .minicart-wrapper .showcart.action .counter .counter-number {
  line-height: 0;
  margin-top: 10px;
  display: inline-block;
}

@media screen and (min-width: 992px) {
  body .minicart-wrapper .showcart.action .counter .counter-number {
    margin-top: 12px;
  }
}

/* add lines on mobile */
@media screen and (max-width: 991.98px) {
  body .ntrn__toggle {
    position: relative;
    width: 20px;
    height: 20px;
    margin-right: 2rem;
  }
  body .ntrn__toggle:after {
    background-color: #ececec;
    content: "";
    height: 48px;
    width: 1px;
    position: absolute;
    top: -14px;
    right: -16px;
  }
  body .ntrn__toggle:before {
    font-size: 26px;
  }
}

@media screen and (max-width: 991.98px) {
  body .minicart-wrapper {
    position: relative;
  }
  body .minicart-wrapper:before {
    background-color: #ececec;
    content: "";
    height: 48px;
    width: 1px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

/*
body .header.content .block.block-search,
body.is-search-open .header.content .block.block-search {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        margin-right: 0;
        margin-left: auto;
    }
}
 */
html.ntrn__opened .minicart-wrapper:before {
  display: none;
}

html.ntrn__opened .ntrn__toggle {
  background-color: #fff;
}

/*
body.is-search-open .form.minisearch>.actions {
    right: -100%;
}
 */
body .sections.nav-sections {
  z-index: 0;
  top: 48px;
}

@media screen and (min-width: 992px) {
  body .sections.nav-sections {
    top: 0;
  }
}

/*
#search_autocomplete {
    box-shadow: 0 20px 40px rgba(0,0,0,.15);
    margin-top: 0;
    padding-bottom: 0;
}
 */
/* navigation overrides */
@media screen and (min-width: 992px) {
  body .ntrn__menu > li > a {
    font-size: 31px;
  }
}

@media screen and (min-width: 992px) {
  body .page-wrapper .nav-sections .nav-sections-items {
    max-width: none;
    padding: 0;
  }
}

@media screen and (min-width: 992px) {
  body .ntrn > .ntrn__menu {
    max-width: none;
    justify-content: center;
  }
}

@media screen and (min-width: 992px) {
  body .ntrn > .ntrn__menu .ntrn__cols {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 992px) {
  body .ntrn > .ntrn__menu .ntrn__col {
    padding-left: 40px;
  }
}

body .ntrn > .ntrn__menu p.maxlist-more {
  display: block;
  margin-top: 10px;
}

/* fixes not able to scroll past bottom menu list item on position sticky */
@media screen and (min-width: 992px) {
  html.ntrn__loaded .ntrn__menu > li > ul {
    height: 490px;
    overflow-y: auto;
  }
}

/* override styles only for $tweakpoint-nav-toggle */
@media screen and (min-width: 992px) {
  body .navigation li.level1 {
    width: calc(100% / 5);
  }
  body .navigation .submenu.level0 {
    flex-wrap: wrap;
  }
  body .navigation .submenu.level0 > .submenu > .level1 {
    width: calc(100% / 5);
  }
  body .navigation .all-category {
    display: block;
    width: 100%;
    text-align: right;
    position: relative;
    margin-top: 20px;
  }
  body .navigation .all-category a {
    display: inline-block;
    width: auto;
  }
  body .navigation .all-category a:before {
    display: none;
  }
  body .navigation .all-category a:after {
    display: inline-block;
    margin-left: .5rem;
    font-family: "ms-icons";
    content: "";
    line-height: 0;
    vertical-align: top;
    margin-top: 10px;
  }
}

.page-header {
  position: sticky;
  top: 0;
  left: 0;
}

body.cms-home .page-wrapper .page-main {
  max-width: none;
  margin: 0;
  padding: 0;
}

/* remove the sticky customer logged in notification when logged in as customer */
body .lac-notification-sticky {
  display: none;
}

/* align page titles within contained bounderies */
/* add max width to registration success page */
/* header login dropdown */
@media screen and (min-width: 992px) {
  .customer-welcome.active .header.links > li > a {
    color: #000;
  }
}

/* minicart product name links */
.product-item-name > a {
  color: #000;
}

.product-item-name > a:hover {
  color: #000;
}

/* see details toggle in minicart, cart and checkout */
.product.options .toggle:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.product.options .toggle:after {
  margin-left: .5rem;
}

.product.options.active .toggle:after {
  content: "";
}

/* minicart remove button bugfix (margin removed to icon due to icon edit being hidden) */
body .block-minicart .minicart-items .product-item-details .action.delete:after {
  margin: 0;
}

/* space out the minicart top and bottom */
body .minicart-wrapper .block-minicart .block-content .minicart-items-wrapper {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

/* fix cart icons spacing and position */
body .minicart-wrapper .block-minicart .block-title:before {
  top: 0;
}

/* remove unnecessary title from cart page */
body .cart-container #shopping-cart-table .table-caption {
  display: none;
}

body .cart-container .cart.table-wrapper {
  margin-top: 0;
}

body .cart-container .cart-container-sidebar {
  padding-top: 0;
  margin-top: 0;
}

body .cart-container .totals-sticky {
  top: 48px;
}

@media screen and (min-width: 992px) {
  body .cart-container .cart-container-sidebar .cart-summary {
    top: 214px;
  }
}

/* cart page background colours and border size */
.cart-container-main .cart.table .cart.item:not(.message) {
  border-width: 2px;
}

/* discount letter spacing */
body .block.discount .title,
body .block.giftcard .title,
body .checkout-cart-index table caption,
body .gift-item-block .title,
body .gift-options-cart-item .gift-options-title {
  letter-spacing: -0.02em;
}

/* font size for apply discount link fix */
body .block.discount .actions-toolbar .action.apply,
body .block.discount .actions-toolbar .action.check,
body .block.discount .actions-toolbar .action.primary,
body .block.giftcard .actions-toolbar .action.apply,
body .block.giftcard .actions-toolbar .action.check,
body .block.giftcard .actions-toolbar .action.primary,
body .gift-item-block .actions-toolbar .action.apply,
body .gift-item-block .actions-toolbar .action.check,
body .gift-item-block .actions-toolbar .action.primary,
body.account .page-main .block-content .action,
body.account .page-main .block-title .action,
body.account .table tbody tr td .action,
body.account .table tbody tr td .action.activate,
body.account .table tfoot tr td .action,
body.account .table tfoot tr td .action.activate,
body.account .table tbody tr td .action,
body.account .table tfoot tr td .action {
  font-size: 100%;
}

/* remove button global colour override */
body .action.delete,
body .action.action-delete,
body .cart-container-main .cart.table .action.action-delete {
  color: #b70e0e;
}

body .action.delete:hover,
body .action.action-delete:hover,
body .cart-container-main .cart.table .action.action-delete:hover {
  color: #97999C;
}

/* toggle close button not looking correct */
html.ntrn__opened .ntrn__toggle {
  width: 20px;
  height: 20px;
  margin-right: 2rem;
  overflow: visible;
}

html.ntrn__opened .ntrn__toggle:before {
  font-size: 23px;
}

/* stop animation when search active */
html.ntrn__opened .action.showcart,
html.ntrn__opened .field.search > .label {
  transform: none;
  opacity: 1;
  visibility: visible;
  transition-delay: unset;
}

html.ntrn__opened .minicart-wrapper:before {
  background-color: #ececec;
  content: "";
  height: 48px;
  width: 1px;
  position: absolute;
  top: 0;
  left: 0;
}

/* align items within mini cart */
body .block-minicart .minicart-items .product .product-item-details .product-item-pricing {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

body .block-minicart .minicart-items .product .product-item-details .product-item-pricing .price-container {
  width: unset;
  height: unset;
  line-height: normal;
  float: none;
}

body .block-minicart .minicart-items .product .product-item-details .details-qty.qty {
  width: unset;
  float: none;
}

body .block-minicart .minicart-items .product .product-item-details .details-qty.qty input {
  padding: .5rem;
}

/* sitemap styles */
body.mageworx_htmlsitemap-index-index .page-main {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

body.mageworx_htmlsitemap-index-index .page-title-wrapper {
  margin-bottom: 1.5rem;
}

body.mageworx_htmlsitemap-index-index .xsitemap-categories,
body.mageworx_htmlsitemap-index-index .xsitemap-pages {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  overflow: auto;
}

@media screen and (max-width: 767.98px) {
  body.mageworx_htmlsitemap-index-index .xsitemap-categories,
  body.mageworx_htmlsitemap-index-index .xsitemap-pages {
    width: 100%;
    float: none;
  }
}

body.mageworx_htmlsitemap-index-index .xsitemap li {
  position: relative;
}

body.mageworx_htmlsitemap-index-index .xsitemap li:before {
  display: inline-block;
  content: '-';
}

/* price - price from and each */
body.catalog-product-view .product-info-price [data-price-type=finalPrice] .price {
  font-family: "Univers Condensed Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 29px;
  font-weight: 700;
  letter-spacing: -0.02em;
}

@media screen and (min-width: 992px) {
  body.catalog-product-view .product-info-price [data-price-type=finalPrice] .price {
    font-size: 49px;
  }
}

.from-price,
.each-price,
.exvat {
  font-family: "Univers LT Std", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  font-weight: 400;
  text-transform: capitalize;
  letter-spacing: normal;
}

/* excluding VAT */
.exvat {
  display: block;
  color: #97999C;
}

/* modal fix for mobile - when clicking edit address and close modal is triggering scroll-x */
body .modals-wrapper {
  display: none;
}

body._has-modal .modals-wrapper {
  display: block;
}

/* ampicker date on checkout styles */
.ampickup-wrapper.control.ampickup-date-wrapper {
  align-items: flex-start !important;
}

.ampickup-wrapper.control.ampickup-date-wrapper .label:after {
  display: none;
}

.ampickup-wrapper.control.ampickup-date-wrapper .ampickup-store.select {
  flex: 1 1 auto;
}

@media screen and (max-width: 767.98px) {
  .ampickup-wrapper.control.ampickup-date-wrapper .ampickup-store.select {
    width: calc(100% - 20px);
  }
}

@media screen and (min-width: 768px) {
  .ampickup-wrapper.control.ampickup-date-wrapper:after {
    position: relative !important;
  }
}

@media screen and (min-width: 768px) {
  body .ampickup-store-container.-checkout .ampickup-field.-date {
    display: flex;
    width: 100%;
    padding-right: 0;
  }
}

body .ampickup-store-container.-checkout .ampickup-field.-date .label {
  position: relative;
  display: block;
  font-weight: 700;
  text-transform: capitalize;
}

body .ampickup-store-container.-checkout .ampickup-field.-date .label > span:after {
  display: inline;
  content: ':';
}

@media screen and (min-width: 430px) {
  body .ampickup-store-container.-checkout .ampickup-field.-date .label {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 0;
    width: 100%;
    padding-right: 1rem;
    max-width: 180px;
    vertical-align: middle;
  }
}

body .ampickup-store-container.-checkout .ampickup-field.-date .control {
  width: auto;
  flex: 1 1 auto;
  position: relative;
}

body .ampickup-store-container.-checkout .ampickup-field.-date .control:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

body .ampickup-store-container.-checkout .ampickup-field.-date .control:before {
  position: absolute;
  top: 0;
  left: 10px;
  line-height: 38px;
}

body .ampickup-store-container.-checkout .ampickup-field.-date .control:after {
  top: 0;
}

@media screen and (min-width: 768px) {
  body .ampickup-store-container.-checkout .ampickup-field.-date .control:after {
    position: relative !important;
  }
}

body .ampickup-store-container.-checkout .ampickup-field.-date .control input {
  flex: unset;
  margin-right: 10px;
  padding-left: 40px;
}

@media screen and (max-width: 767.98px) {
  body .ampickup-store-container.-checkout .ampickup-field.-date .control input {
    width: calc(100% - 20px);
  }
}

/* store switcher flag icon styles */
.flag-icon {
  display: inline-block;
  max-width: 16px;
  margin-right: .5rem;
}

.switcher-language .switcher-trigger,
.switcher-language .switcher-option a {
  display: flex;
}

html.ntrn__opened .switcher-language {
  margin: 0;
}

html.ntrn__opened .switcher-language .switcher-trigger {
  height: auto;
  max-width: none;
}

/* fix width of the dropdown country picker issue seen with width set to 100% of parent on LGH-309 */
@media screen and (min-width: 992px) {
  .switcher .options ul.dropdown {
    width: unset;
    min-width: 130px;
  }
}

/* fix alignment of the country text alongside country flags */
.switcher .options .switcher-trigger > strong,
.switcher .options ul.dropdown > .switcher-option > a > span {
  margin-top: 3px;
}
