.homepage-usp-block {
  @if $homepage-usp-full-width == true {
    @include full-width;
  }
  color: $homepage-usp-block-color;
  background: $homepage-usp-block-bg;
  text-transform: uppercase;
  height: $homepage-usp-height-mobile;

  @include respond-to-up(lg) {
    height: $homepage-usp-height-desktop;
  }

  ul {
    @extend %u-list-unstyled;
    @extend %u-flex-row-xcenter-ycenter;
    @if $homepage-usp-full-width == true {
      max-width: $homepage-usp-max-width;
      margin: 0 auto;
    } @else {
      margin: 0;
    }

    height: 100%;
    padding: 0;
    justify-content: space-around;
    font-size: $homepage-usp-text-size;
    letter-spacing: 0.02em;
    flex-wrap: wrap;
    overflow: hidden;

    > li {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $homepage-usp-height-mobile;
      padding: 0 spacers(1);
      margin: 0;
      width: 100%;

      @include respond-to-up(lg) {
        width: auto;
        height: $homepage-usp-height-desktop;
      }
    }

    @include respond-to-up(lg) {
      flex-wrap: nowrap;
    }
  }

  .slick-slide {
    text-align: center;
  }

  .homepage-slider-container & {
    position: absolute;
    z-index: z('above');
  }
}
