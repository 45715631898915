.footer-social-links {
  @extend %u-list-unstyled;
  @extend %u-flex-row-xcenter-ycenter;
  margin-bottom: 0;
  padding-top: $footer-content-padding-mobile;

  @include respond-to-up($footer-accordion-breakpoint) {
    justify-content: flex-start;
    padding-top: 0;
  }
  @include respond-to-up(xl) {
    padding-top: $footer-content-padding-desktop;
  }

  > li {
    margin: $footer-social-links-spacing;

    &:first-child {
      @include respond-to-up($footer-accordion-breakpoint) {
        margin-left: 0;
      }
    }

    > a {
      @include size($footer-social-links-icon-size);
      position: relative;
      display: block;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;

      &:after {
        @extend %u-transform-fade-transition;
        @include absolute(0, 0);
        width: 100%;
        height: 100%;
        z-index: 1;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        content: "";
        transform-origin: 50% 50%;
        transform: scale(0);
        visibility: hidden;
        opacity: 0;
      }

      > span {
        @extend %u-sr-only;
      }

      &:hover {
        overflow: visible;

        &:after {
          transform: scale(1.1);
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }

  &__twitter > a {
    background: url("../images/social/twitter.svg");

    &:after {
      background: url("../images/social/twitter-inverted.svg");
    }
  }

  &__facebook > a {
    background: url("../images/social/facebook.svg");

    &:after {
      background: url("../images/social/facebook-inverted.svg");
    }
  }

  &__youtube > a {
    background: url("../images/social/youtube.svg");

    &:after {
      background: url("../images/social/youtube-inverted.svg");
    }
  }

  &__instagram > a {
    background: url("../images/social/instagram.svg");

    &:after {
      background: url("../images/social/instagram-inverted.svg");
    }
  }
}
