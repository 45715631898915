$button-styles-override: false !default;

$button-border-radius: 0 !default;
$button-mobile-max-width: 222px !default;
$button-disabled-opacity: 0.4 !default;
$button-padding-x: 34px !default;
$button-padding-y: 4px !default;

$button-promo-bg: theme-color('primary') !default;
$button-promo-hover-bg: lighten(theme-color('primary'), 25%) !default;
$button-promo-color: $white !default;
$button-promo-hover-color: $white !default;
$button-promo-padding-x: 54px !default;
$button-promo-padding-y: 26px !default;
$button-promo-text-transform: uppercase !default;

$button-yell-bg: theme-color('secondary') !default;
$button-yell-hover-bg: darken(theme-color('secondary'), 25%) !default;
$button-yell-color: $white !default;
$button-yell-hover-color: $white !default;
$button-yell-padding-x: 36px !default;
$button-yell-padding-y: 14px !default;
$button-yell-text-transform: normal !default;

$button-shout-bg: theme-color('positive') !default;
$button-shout-hover-bg: darken(theme-color('positive'), 25%) !default;
$button-shout-color: $white !default;
$button-shout-hover-color: $white !default;
$button-shout-padding-x: 36px !default;
$button-shout-padding-y: 14px !default;
$button-shout-text-transform: normal !default;

$button-cheer-bg: theme-color('primary') !default;
$button-cheer-hover-bg: lighten(theme-color('primary'), 25%) !default;
$button-cheer-color: $white !default;
$button-cheer-hover-color: $white !default;
$button-cheer-padding-x: 36px !default;
$button-cheer-padding-y: 14px !default;
$button-cheer-text-transform: uppercase !default;

$button-cheer-inverted-bg: $white !default;
$button-cheer-inverted-hover-bg: darken($white, 25%) !default;
$button-cheer-inverted-color: theme-color('primary') !default;
$button-cheer-inverted-hover-color: theme-color('primary') !default;
$button-cheer-inverted-padding-x: 36px !default;
$button-cheer-inverted-padding-y: 14px !default;
$button-cheer-inverted-text-transform: uppercase !default;

$button-whistle-bg: transparent !default;
$button-whistle-hover-bg: transparent !default;
$button-whistle-color: theme-color('primary') !default;
$button-whistle-underline-width: 1px !default;
$button-whistle-underline-color: $gray-400 !default;
$button-whistle-hover-color: theme-color('primary') !default;
$button-whistle-padding-x: 4px !default;
$button-whistle-padding-y: 5px !default;
$button-whistle-text-transform: normal !default;

$button-murmur-bg: transparent !default;
$button-murmur-hover-bg: transparent !default;
$button-murmur-border: 1px solid $gray-400 !default;
$button-murmur-color: $gray-600 !default;
$button-murmur-hover-color: darken($gray-600, 25%) !default;
$button-murmur-padding-x: 36px !default;
$button-murmur-padding-y: 14px !default;
$button-murmur-text-transform: normal !default;

$button-whisper-bg: transparent !default;
$button-whisper-hover-bg: transparent !default;
$button-whisper-color: theme-color('primary') !default;
$button-whisper-hover-color: lighten(theme-color('primary'), 25%) !default;
$button-whisper-padding-x: 7.5px !default;
$button-whisper-padding-y: 14px !default;
$button-whisper-letter-spacing: 0.025em !default;
$button-whisper-icon-spacing-x: 5px !default;
$button-whisper-icon: 'chevron-right' !default;
$button-whisper-text-transform: uppercase !default;
