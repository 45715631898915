@if $search-loader-enabled {
  .search-loader {
    @include absolute(0, 0);
    @include size($search-icon-size * 2);
    height: 94%;
    right: 2px;
    background-color: $search-input-bg;
    display: none;

    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
      @include size($search-icon-size * 1.3);
      height: 100%;
      top: 1px;
      right: -3px;
      margin-right: 4px;
    }

    svg {
      width: 100%;
      height: 100%;
      transform: rotate(45deg); // initial degree
      animation: loader-rotate $search-loader-period infinite ease-in;
      animation-delay: 1s;

      @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        @include size($search-icon-size * 1.3);
        position: relative;
        top: -8px;
        left: 4px;
      }
    }

    &__grip {
      fill: $search-loader-color;
      stroke: $search-loader-color;
      stroke-width: 12;
      stroke-dasharray: 200;
      animation: loader-scale $search-loader-period infinite $transition-timing-ease-in-out-quad;
      animation-delay: 1s;
    }

    &__background {
      fill: transparent;
      stroke: rgba($search-loader-color, .2);
      stroke-width: 12;
    }

    &__outer {
      fill: transparent;
      stroke: $search-loader-color;
      stroke-width: 12;
      stroke-dasharray: 500;
      stroke-dashoffset: 12;
      animation: loader-circle $search-loader-period infinite $transition-timing-ease-in-out-circ;
      animation-delay: 1s;
    }

    .ajax-loading .minisearch.active & {
      display: block;
    }
  }

  @keyframes loader-scale {
    0%, 100% {
      stroke-dashoffset: 10;
    }
    15%, 80% {
      stroke-dashoffset: 190;
    }
  }

  @keyframes loader-circle {
    0% {
      stroke-dashoffset: 249;
    }
    20% {
      stroke-dashoffset: 249;
      opacity: 0;
    }
    25% {
      stroke-dashoffset: 495;
    }
    35% {
      opacity: 1;
    }
    50% {
      stroke-dashoffset: 440;
    }
    80%, 100% {
      stroke-dashoffset: 249;
    }
  }

  @keyframes loader-rotate {
    0%, 20% {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    75%, 100% {
      -webkit-transform: rotate(calc(360deg * 5 + 45deg));
      transform: rotate(calc(360deg * 5 + 45deg));
    }
  }
} @else {
  .search-loader {
    display: none;
  }
}
