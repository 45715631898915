// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$secondary-font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
$font-family-heading:         $secondary-font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-sm:                ($font-size-base * .875) !default;
$font-size-xs:                ($font-size-base * .75) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-semi-bold:       600 !default;
$font-weight-bold:            800 !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;

$headings-margin-bottom:      ($spacer / 2) !default;
$headings-font-family:        false !default;
$headings-font-weight:        700 !default;
$headings-line-height:        1.0 !default;

$headings-style-weight:       700 !default;
$headings-style-transform:    uppercase !default;
$headings-style-letter-spacing:    0.05em !default;

$small-font-size:             80% !default;

$text-muted:                  $gray-600 !default;

$blockquote-small-color:      $gray-600 !default;
$blockquote-font-size:        ($font-size-base * 1.25) !default;

$hr-border-color:             rgba($black, .1) !default;
$hr-border-width:             $border-width !default;

$dt-font-weight:              $font-weight-bold !default;

$list-inline-padding:         .5rem !default;

$hr-margin-y:                 $spacer !default;
$hr-width:                    1px !default;
$hr-bg:                       $gray-400 !default;