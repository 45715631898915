%button-base {
  @include button-base();
  padding: $button-padding-y $button-padding-x;
  text-align: center;
  justify-content: center;
  align-items: center;
}


%button-promo {
  background: $button-promo-bg;
  color: $button-promo-color;
  text-transform: $button-promo-text-transform;
  font-weight: $font-weight-bold;
  padding: $button-promo-padding-y $button-promo-padding-x;

  @include hover-focus {
    color: $button-promo-hover-color;
    background: $button-promo-hover-bg;
  }
}

%button-yell {
  background: $button-yell-bg;
  color: $button-yell-color;
  text-transform: $button-yell-text-transform;
  font-weight: $font-weight-bold;
  padding: $button-yell-padding-y $button-yell-padding-x;

  @include hover-focus {
    color: $button-yell-hover-color;
    background: $button-yell-hover-bg;
  }
}

%button-whisper {
  @include ms-icon($button-whisper-icon, 70%, after);
  background: $button-whisper-bg;
  color: $button-whisper-color;
  text-transform: $button-whisper-text-transform;
  font-weight: $font-weight-bold;
  padding: $button-whisper-padding-y $button-whisper-padding-x;
  letter-spacing: $button-whisper-letter-spacing;
  position: relative;
  display: inline-block;

  @include hover-focus {
    color: $button-whisper-hover-color;
    background: $button-whisper-hover-bg;
  }

  &:after {
    margin-top: 2px;
    margin-left: $button-whisper-icon-spacing-x;
  }
}

%button-shout {
  background: $button-shout-bg;
  color: $button-shout-color;
  text-transform: $button-shout-text-transform;
  font-weight: $font-weight-bold;
  padding: $button-shout-padding-y $button-shout-padding-x;

  @include hover-focus {
    color: $button-shout-hover-color;
    background: $button-shout-hover-bg;
  }
}

%button-cheer {
  background: $button-cheer-bg;
  color: $button-cheer-color;
  text-transform: $button-cheer-text-transform;
  font-weight: $font-weight-bold;
  padding: $button-cheer-padding-y $button-cheer-padding-x;

  @include hover-focus {
    color: $button-cheer-hover-color;
    background: $button-cheer-hover-bg;
  }
}

%button-cheer-inverted {
  background: $button-cheer-inverted-color;
  color: $button-cheer-inverted-bg;
  text-transform: $button-cheer-inverted-text-transform;
  font-weight: $font-weight-bold;
  padding: $button-cheer-inverted-padding-y $button-cheer-inverted-padding-x;

  @include hover-focus {
    color: $button-cheer-inverted-hover-bg;
    background: $button-cheer-inverted-hover-color;
  }
}


%button-murmur {
  background: $button-murmur-bg;
  color: $button-murmur-color;
  text-transform: $button-murmur-text-transform;
  font-weight: $font-weight-bold;
  padding: $button-murmur-padding-y $button-murmur-padding-x;
  border: $button-murmur-border;

  @include hover-focus {
    color: $button-murmur-hover-color;
    border-color: $button-murmur-hover-color;
    background: $button-murmur-hover-bg;
  }
}

%button-whistle {
  position: relative;
  text-decoration: none;
  background: $button-whistle-bg;
  color: $button-whistle-color;
  text-transform: $button-whistle-text-transform;
  font-weight: $font-weight-bold;
  padding: $button-whistle-padding-y $button-whistle-padding-x;

  @include hover-focus {
    color: $button-whistle-hover-color;
    background: $button-whistle-hover-bg;
  }

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: $button-whistle-underline-width;
    bottom: 0;
    left: 0;
    background: $button-whistle-underline-color;
  }
}
