/**
 * Panel Header Styles
 */
.header.panel {
  @extend %u-flex-row-xcenter-ycenter;
  @extend %u-font-xsmall;
  @include size(100%, $header-panel-height);
  justify-content: flex-end;
  background: $header-panel-bg;
  padding: $header-panel-padding;

  @if ($header-panel-hide-under != false) {
    display: none;

    @include respond-to-up($header-panel-hide-under) {
      display: flex;
    }
  }
}

.page-header > .panel.wrapper {
  background: $header-panel-bg;
}

.header-panel__block,
.header.links {
  @extend %u-list-unstyled;
  @extend %u-flex-col-xcenter-ycenter;
  margin-bottom: 0;

  a {
    @include animated-underline();
    display: inline-block;
    color: $header-panel-text-color;
  }

  .header.panel & {
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;
    color: $header-panel-text-color;
  }
}

.header-panel__block {
  .header.panel & {
    flex: 1 1 auto;
    justify-content: flex-start;

    > p:last-of-type {
      margin-bottom: 0;
    }
  }
}

//
// Add 'or' using data-label attribute
//
.header.panel > .header {
  height: 100%;
}

.header.panel > .header > .authorization-link {
  @extend %u-flex-row-xcenter-ycenter;

  &:after {
    content: attr(data-label);
    margin: 0 spacers(3);
  }
}

.header.panel > .header > .authorization-link,
.welcome__message {
  @include ms-icon('user', $header-user-icon-size) {
    margin-right: spacers(3);
  }
}

.welcome__message {
  @extend %u-flex-row-xcenter-ycenter;
  padding-left: $customer-message-spacing-x;

  @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
    padding: spacers(2) 0;
    background: $dropdown-toggle-active-bg;
    font-weight: $font-weight-bold;
  }

  &:before {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
      display: none;
    }
  }
}

