/**
 * Site logo
 */
.logo {
  > img {
    .header & {
      height: 28px;

      @include respond-to-up($tweakpoint-nav-toggle) {
        height: 55px;
      }
    }
  }
}