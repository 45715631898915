/**
 * Minicart wrapper styles
 */
.minicart-wrapper {
  position: relative;

  &:after {
    @extend %u-fixed-backdrop;
    opacity: 0;
    visibility: hidden;
  }

  &.active {
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  .showcart {
    position: relative;

    .counter-label,
    .text {
      @extend %u-sr-only;
    }

    &.action {
      @include ms-icon($minicart-icon, $minicart-icon-size);
      @include size($minicart-button-width, $minicart-button-height);
      position: relative;
      display: inline-block;
      color: $header-content-color;
      text-decoration: none;
      text-align: center;
      padding: 0;

      &:before {
        position: relative;
        top: 13px;
      }

      &:hover,
      &:focus {
        outline: none;
      }

      &.active {
        background-color: $minicart-active-background;
      }

      .counter {
        @include absolute(4px, auto, auto, ($minicart-icon-size + 4px));
        background: $counter-light-bg;
        color: $counter-light-color;
        font-size: $counter-size;
        min-width: 22px;
        min-height: 22px;
        padding: 2px 6px;
        border-radius: 1000px;
        text-align: center;
        width: auto;

        @include respond-to-down(md) {
          font-size: 10px;
          min-width: 18px;
          margin-left: 4px;
          left: 22px;
        }

        &.empty {
          opacity: 0;
        }
      }
    }
  }
}