// Blank theme Mixins override
//

// Remove animated underline
@mixin animated-underline($color: $link-hover-color, $width: $border-width, $pseudo: 'after') {}

// hover focus mixin
@mixin hover-focus {
    &:focus,
    &:hover {
        @content;
    }
}


@mixin font-family-heading () {
    font-family: $font-family-heading;
    font-weight: 900;
    letter-spacing: -0.02em;
    text-transform: uppercase;
}

@mixin font-family-condensed () {
    font-family: $font-family-condensed;
    font-weight: bold;
    letter-spacing: -0.02em;
    text-transform: uppercase;
}


@mixin contained-max-width () {
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}
