.footer.content {
  background: $footer-links-bg;
  color: $footer-links-color;
  padding-bottom: $footer-content-padding-mobile;

  @include respond-to-up($footer-accordion-breakpoint) {
    padding-bottom: $footer-content-padding-desktop;
  }
}

.footer.links {
  @extend %u-list-unstyled;
  @extend %u-flex-row-xcenter-ycenter;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: $footer-links-max-width;
  margin: 0 auto;
  padding-top: $footer-content-padding-mobile;

  @include respond-to-up(md) {
    justify-content: space-between;
    padding-top: $footer-content-padding-desktop;
  }

  ul {
    @extend %u-list-unstyled;
    height: 100%;
  }

  .footer-nav-heading {
    @extend %u-heading-style;
    @extend %ms-icon-align-right;
    @include ms-icon($footer-heading-icon, "xs");
    @include fluid-type($footer-heading-fluid-size-mobile);
    display: block;
    font-family: $font-family-heading;
    color: $footer-links-color;
    padding: $footer-heading-padding;

    @include respond-to-up($footer-accordion-breakpoint) {
      padding-top: 0;
      padding-left: 0;
      padding-right: 0;
      @include fluid-type($footer-heading-fluid-size-desktop);
    }

    &:before {
      @extend %u-transform-transition;
      transition-duration: $transition-duration-ease-out;
      transition-timing-function: $transition-timing-sharp;
      transition-delay: 0.05s;
      transform-origin: 50% 50%;
      right: 10px;

      @include respond-to-up($footer-accordion-breakpoint) {
        display: none;
      }
    }

    &[href] {
      text-decoration: none;
    }
  }

  .footer-logo {
    display: none;
    @include respond-to-up($footer-accordion-breakpoint) {
      display: block;
    }
    @include respond-to-between($footer-accordion-breakpoint, lg) {
      margin-right: $footer-content-padding-desktop;
    }
  }

  .item {
    padding: $footer-links-item-padding;

    @include respond-to-up($footer-accordion-breakpoint) {
      padding: 0.5rem 0;
    }

    > .widget a,
    > a,
    > span {
      @include animated-underline($footer-links-color);
      font-size: $footer-links-font-size;
      color: $footer-heading-color;
    }
  }

  > li {
    width: 100%;
    padding: 0;

    @include respond-to-up($footer-accordion-breakpoint) {
      width: auto;
      flex: 0 1 auto;
      padding: $footer-links-item-padding;
      padding-top: 0;
      padding-bottom: 0;
    }

    &:first-child {
      @if ($footer-columns == 4) {
        order: 1;
        @include respond-to-up($footer-accordion-breakpoint) {
          order: 0;
        }
        @include respond-to-between($footer-accordion-breakpoint, lg) {
          flex: 0 1 100%;
          display: flex;
          align-items: center;
          margin-bottom: $footer-content-padding-desktop/2;
        }
      }
    }
  }

  .heading:not(:first-child) {
    > ul {
      @extend %u-max-height-transition;
      max-height: 0;
      overflow: hidden;

      @include respond-to-up($footer-accordion-breakpoint) {
        max-height: none;
        overflow: visible;
      }

      > li:last-child {
        @include respond-to-up($footer-accordion-breakpoint) {
          padding-bottom: 0;
        }
      }
    }

    &.is-active > ul {
      max-height: 250px;
      overflow: auto;
    }
  }

  .is-active {
    .footer-nav-heading {
      &:before {
        content: map-get($ms-icons, 'minus');
      }
    }
  }
}
