$ms-icons: (

    "arrow-down" : "\E001",

    "arrow-left" : "\E002",

    "arrow-right" : "\E003",

    "arrow-up" : "\E004",

    "calendar" : "\E005",

    "camera" : "\E006",

    "check" : "\E007",

    "chevron-down" : "\E008",

    "chevron-left" : "\E009",

    "chevron-right" : "\E00A",

    "chevron-up" : "\E00B",

    "clock" : "\E00C",

    "cog" : "\E00D",

    "compare" : "\E00E",

    "edit" : "\E00F",

    "grid" : "\E010",

    "heart-solid" : "\E011",

    "heart" : "\E012",

    "info" : "\E013",

    "list" : "\E014",

    "mail" : "\E015",

    "map-pin" : "\E016",

    "menu" : "\E017",

    "message-square" : "\E018",

    "minus" : "\E019",

    "plus" : "\E01A",

    "search" : "\E01B",

    "settings" : "\E01C",

    "share-2" : "\E01D",

    "shopping-bag" : "\E01E",

    "shopping-cart" : "\E01F",

    "star-solid" : "\E020",

    "star" : "\E021",

    "trash" : "\E022",

    "user" : "\E023",

    "x" : "\E024",

);