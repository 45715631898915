.header.panel .header.links {
    li.nav.item {
        @include respond-to-up($tweakpoint-nav-toggle) {
            padding-left: .5rem;
        }
    }
}

/* add override styles to text within the panel header */
.header.panel .header-panel__block,
.header.panel .header.links {
    text-transform: none;
    font-size: 1rem;
}

/* add link color override for panel__block */
.header.panel .header-panel__block a {
    color: $text-muted;
}

/* add some style and spacing to the or in between login and sign up */
.header.panel>.header>.authorization-link:after {
    margin: 0 6px;
}

.panel.header p {
    margin-bottom: 0;
}

/* search input and cart overrides */
/*
body .form.minisearch .control>input,
body .form.minisearch>.actions .action.search  {
    border: $search-input-border;
    border-radius: 0;
}
body .form.minisearch .control>input {
    font-weight: bold;
    color: $black;

    &::placeholder {
        font-weight: bold;
        color: $black;
    }

    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 20px;
    }
}
body .form.minisearch>.actions .action.search {
    opacity: 1;
    border-left: 0;

    &:before {
        font-weight: bold;
    }

    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        border-right: 0;
    }
}

body .header.content .block-search {
    @include respond-to-up($tweakpoint-nav-toggle) {
        margin-right: 0;
    }
}
*/

body .minicart-wrapper .showcart.action {
    @include respond-to-up($tweakpoint-nav-toggle) {
        background-color: map-get($theme-colors, 'primary');
        color: $white;
        border: $search-input-border;
        border-left: 0;
        height: 46px;

        &:before {
            top: 9px;
        }
    }
}

body .minicart-wrapper .showcart.action .counter {
    background-color: $black;
    color: $white;
    min-height: 18px;
    padding: 0;

    @include respond-to-up($tweakpoint-nav-toggle) {
        min-height: 22px;
    }

    .counter-number {
        line-height: 0;
        margin-top: 10px;
        display: inline-block;

        @include respond-to-up($tweakpoint-nav-toggle) {
            margin-top: 12px;
        }
    }
}

/* add lines on mobile */
body .ntrn__toggle {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        position: relative;
        width: 20px;
        height: 20px;
        margin-right: 2rem;

        &:after {
            background-color: #ececec;
            content: "";
            height: 48px;
            width: 1px;
            position: absolute;
            top: -14px;
            right: -16px;
        }

        &:before {
            font-size: 26px;
        }
    }
}
// body .block.block-search,
body .minicart-wrapper {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        position: relative;

        &:before {
            background-color: #ececec;
            content: "";
            height: 48px;
            width: 1px;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

/*
body .header.content .block.block-search,
body.is-search-open .header.content .block.block-search {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        margin-right: 0;
        margin-left: auto;
    }
}
 */

// html.ntrn__opened .block.block-search,
html.ntrn__opened .minicart-wrapper {
    &:before {
        display: none;
    }
}

html.ntrn__opened .ntrn__toggle {
    background-color: $white;
}

/*
body.is-search-open .form.minisearch>.actions {
    right: -100%;
}
 */

body .sections.nav-sections {
    z-index: 0;
    top: 48px;

    @include respond-to-up($tweakpoint-nav-toggle) {
        top: 0;
    }
}

/*
#search_autocomplete {
    box-shadow: 0 20px 40px rgba(0,0,0,.15);
    margin-top: 0;
    padding-bottom: 0;
}
 */

/* navigation overrides */
body .ntrn__menu>li>a {
    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 31px;
    }
}

body .page-wrapper .nav-sections .nav-sections-items {
    @include respond-to-up($tweakpoint-nav-toggle) {
        max-width: none;
        padding: 0;
    }
}

body .ntrn>.ntrn__menu {
    @include respond-to-up($tweakpoint-nav-toggle) {
        max-width: none;
        justify-content: center;
    }

    .ntrn__cols {
        @include respond-to-up($tweakpoint-nav-toggle) {
            max-width: 1400px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .ntrn__col {
        @include respond-to-up($tweakpoint-nav-toggle) {
            padding-left: 40px;
        }
    }

    p.maxlist-more {
        display: block;
        margin-top: 10px;
    }
}

/* fixes not able to scroll past bottom menu list item on position sticky */
html.ntrn__loaded .ntrn__menu>li>ul {
    @include respond-to-up($tweakpoint-nav-toggle) {
        height: 490px;
        overflow-y: auto;
    }
}
