/**
 * Site logo
 */
.logo {
  .header & {
    @include size($site-logo-mobile-width, $site-logo-mobile-height);
    display: block;

    @include respond-to-up($tweakpoint-nav-toggle) {
      height: auto;
    }
  }

  > img {
    .header & {
      width: auto;
      height: 100%;

      @include respond-to-up($tweakpoint-nav-toggle) {
        width: auto;
        height: auto;
      }
    }
  }
}