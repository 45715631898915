/**
 * Mini search form styles within header content
 */

.form.minisearch {
  @if $header-search-mobile-fullscreen {
    @include respond-to-up($tweakpoint-nav-toggle) {
      position: relative;
    }
  } @else {
    position: relative;
  }

  .label {
    @extend %u-flex-row-xcenter-ycenter;
    @extend %u-bg-color-transition;
    @include ms-icon('search', $search-icon-size);
    @include size($search-button-width, $search-button-height);
    margin-bottom: 0;

    @if $header-search-mobile-fullscreen {
      @include respond-to-up($tweakpoint-nav-toggle) {
        display: none;
      }
    } @else {
      display: none;
    }

    > span {
      @extend %u-sr-only;
    }

    &.active {
      background: $search-active-background;
    }
  }

  .control {
    width: $search-input-width;

    @include respond-to-up(md) {
      width: $search-input-width-md;
    }

    @include respond-to-up(lg) {
      width: $search-input-width-lg;
    }

    @if $header-search-mobile-fullscreen {
      @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        @include fixed(0, 0);
        @include size(100vw, 100vh);
        padding: $header-search-mobile-top-offset $container-padding 0;
        background: $search-backdrop;
        transform: translateY(0%);
        opacity: 1;
        z-index: z(fixed);
        transition: $transition-fade;
      }
    } @else {
      @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        width: 100%;
      }
    }

    .minisearch-close-button {
      @include ms-icon('x');
      display: none;

      @if $header-search-mobile-fullscreen {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
          @include absolute(0, 0);
          @include size($search-button-width, $header-search-mobile-top-offset);
          display: flex;
          align-items: center;
          justify-content: center;
          color: $body-color;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }

    > input {
      width: 100%;
      padding-left: spacers(3);
      height: $search-input-height-sm;
      background: $search-input-bg;
      border: $search-input-border;

      @if $header-search-mobile-fullscreen {
        @include respond-to-up($tweakpoint-nav-toggle) {
          border: 0 none;
          border-left: $search-input-border;
          height: $search-input-height-md;
        }
      } @else {
        border: 0 none;
        border-left: $search-input-border;
        height: $search-input-height-md;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  > .field {
    position: relative;
    z-index: z(below);
    margin-bottom: 0;
  }

  > .actions {
    @extend %u-flex-row-xcenter-ycenter;
    @include absolute(0, 0);
    @include size($search-icon-size * 2, 100%);
    background: $search-input-bg;
    z-index: z(above);

    @if $header-search-mobile-fullscreen {
      @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        @include size($search-input-height-sm);
        top: $header-search-mobile-top-offset;
        right: calc(#{$container-padding} + 0.25rem);
        background: transparent;
      }
    }

    .action.search {
      @include ms-icon('search', $search-icon-size);
      @include size(100%, 100%);
      color: $body-color;
      cursor: pointer;
      box-sizing: border-box;
      padding: 0;

      @if $header-search-mobile-fullscreen {
        @include respond-to-up($tweakpoint-nav-toggle) {
          border-right: $search-input-border;
        }
      } @else {
        border-right: $search-input-border;
      }

      &:before {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
          @include ms-icon-size($search-icon-size * 0.75);
        }
      }

      > span {
        @extend %u-sr-only;
      }
    }
  }

  // Active styles
  @if $header-search-mobile-fullscreen {
    &:not(.active) {
      .control {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
          transform: translateY(-100%);
          opacity: 0;
        }
      }

      > .actions {
        @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
          display: none;
        }
      }
    }
  }
}

