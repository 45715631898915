//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//

//------------------------------------//
// Magesparrow - Blank Theme styles   //
//------------------------------------//
// Do not remove this.
@import "magesparrow-blank/Magento_Theme/web/css/module";
//------------------------------------//


@import "../../../web/css/config";

@import "modules/logo";
@import "modules/header-panel";
@import "modules/nav/navigation";


.page-header {
    position: sticky;
    top: 0;
    left: 0;
}

body.cms-home .page-wrapper .page-main {
    max-width: none;
    margin: 0;
    padding: 0;
}

/* remove the sticky customer logged in notification when logged in as customer */
body .lac-notification-sticky {
    display: none;
}

/* align page titles within contained bounderies */
body.cms-page-view .page-title-wrapper:not(.product) {
    //@include contained-max-width();
}

/* add max width to registration success page */
body.cms-registration-success .page-wrapper .page-main {
    //@include contained-max-width();
    //
    //.page-title-wrapper {
    //    max-width: none;
    //    margin-left: 0;
    //    margin-right: 0;
    //    padding-left: 0;
    //    padding-right: 0;
    //}
}

/* header login dropdown */
.customer-welcome.active {
    .header.links > li > a {
        @include respond-to-up($tweakpoint-nav-toggle) {
            color: $black;
        }
    }
}

/* minicart product name links */
.product-item-name > a {
    color: $black;

    &:hover {
        color: $black;
    }
}

/* see details toggle in minicart, cart and checkout */
.product.options {
    .toggle {
        @include ms-icon('chevron-down', 12px, after);

        &:after {
            margin-left: .5rem;
        }
    }

    &.active {
        .toggle:after {
            content: map-get($ms-icons, 'chevron-up');
        }
    }
}

/* minicart remove button bugfix (margin removed to icon due to icon edit being hidden) */
body .block-minicart .minicart-items .product-item-details .action.delete:after {
    margin: 0;
}

/* space out the minicart top and bottom */
body .minicart-wrapper .block-minicart .block-content .minicart-items-wrapper {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
}

/* fix cart icons spacing and position */
body .minicart-wrapper .block-minicart .block-title:before {
    top: 0;
}

/* remove unnecessary title from cart page */
body .cart-container {
    #shopping-cart-table {
        .table-caption {
            display: none;
        }
    }

    .cart.table-wrapper {
        margin-top: 0;
    }

    .cart-container-sidebar {
        padding-top: 0;
        margin-top: 0;
    }

    .totals-sticky {
        top: 48px;
    }

    .cart-container-sidebar .cart-summary {
        @include respond-to-up($tweakpoint-nav-toggle) {
            top: 214px;
        }
    }
}

/* cart page background colours and border size */
.cart-container-main .cart.table .cart.item:not(.message) {
    border-width: 2px;
}

/* discount letter spacing */
body .block.discount .title,
body .block.giftcard .title,
body .checkout-cart-index table caption,
body .gift-item-block .title,
body .gift-options-cart-item .gift-options-title {
    letter-spacing: -0.02em;
}

/* font size for apply discount link fix */
body .block.discount .actions-toolbar .action.apply,
body .block.discount .actions-toolbar .action.check,
body .block.discount .actions-toolbar .action.primary,
body .block.giftcard .actions-toolbar .action.apply,
body .block.giftcard .actions-toolbar .action.check,
body .block.giftcard .actions-toolbar .action.primary,
body .gift-item-block .actions-toolbar .action.apply,
body .gift-item-block .actions-toolbar .action.check,
body .gift-item-block .actions-toolbar .action.primary,
body.account .page-main .block-content .action,
body.account .page-main .block-title .action,
body.account .table tbody tr td .action,
body.account .table tbody tr td .action.activate,
body.account .table tfoot tr td .action,
body.account .table tfoot tr td .action.activate,
body.account .table tbody tr td .action,
body.account .table tfoot tr td .action {
    font-size: 100%;
}

/* remove button global colour override */
body .action.delete,
body .action.action-delete,
body .cart-container-main .cart.table .action.action-delete {
    color: map-get($theme-colors, 'negative');

    &:hover {
        color: map-get($colors, 'light-grey');
    }
}

/* toggle close button not looking correct */
html.ntrn__opened .ntrn__toggle {
    width: 20px;
    height: 20px;
    margin-right: 2rem;
    overflow: visible;

    &:before {
        font-size: 23px;
    }
}

/* stop animation when search active */
html.ntrn__opened .action.showcart,
html.ntrn__opened .field.search>.label {
    transform: none;
    opacity: 1;
    visibility: visible;
    transition-delay: unset;
}
html.ntrn__opened .minicart-wrapper:before {
    background-color: #ececec;
    content: "";
    height: 48px;
    width: 1px;
    position: absolute;
    top: 0;
    left: 0;
}

/* align items within mini cart */
body .block-minicart .minicart-items .product .product-item-details {
    .product-item-pricing {
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
    }

    .product-item-pricing .price-container {
        width: unset;
        height: unset;
        line-height: normal;
        float: none;
    }

    .details-qty.qty {
        width: unset;
        float: none;

        input {
            padding: .5rem;
        }
    }
}

/* sitemap styles */
body.mageworx_htmlsitemap-index-index {
    .page-main {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .page-title-wrapper {
        margin-bottom: 1.5rem;
    }

    .xsitemap-categories,
    .xsitemap-pages {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        overflow: auto;

        @include respond-to-down(md, $breakpoints, true) {
            width: 100%;
            float: none;
        }
    }

    .xsitemap li {
        position: relative;

        &:before {
            display: inline-block;
            content: '-';
        }
    }
}

/* price - price from and each */
body.catalog-product-view .product-info-price [data-price-type=finalPrice] .price {
    font-family: $font-family-condensed;
    font-size: 29px;
    font-weight: 700;
    letter-spacing: -0.02em;

    @include respond-to-up($tweakpoint-nav-toggle) {
        font-size: 49px;
    }
}

.from-price,
.each-price,
.exvat {
    font-family: $font-family-base;
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    letter-spacing: normal;
}

/* excluding VAT */
.exvat {
    display: block;
    color: map-get($colors, 'light-grey');
}

/* modal fix for mobile - when clicking edit address and close modal is triggering scroll-x */
body .modals-wrapper {
    display: none;
}
body._has-modal .modals-wrapper {
    display: block;
}

/* ampicker date on checkout styles */
.ampickup-wrapper.control.ampickup-date-wrapper {
    align-items: flex-start !important;

    .label:after {
        display: none;
    }
    .ampickup-store.select {
        flex: 1 1 auto;

        @include respond-to-down(md, $breakpoints, true) {
            width: calc(100% - 20px);
        }
    }

    &:after {
        @include respond-to-up(md) {
            position: relative !important;
        }
    }
}

body .ampickup-store-container.-checkout .ampickup-field.-date {
    @include respond-to-up(md) {
        display: flex;
        width: 100%;
        padding-right: 0;
    }

    .label {
        position: relative;
        display: block;
        font-weight: 700;
        text-transform: capitalize;

        > span:after {
            display: inline;
            content: ':';
        }

        @include respond-to-up(smm) {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 0;
            width: 100%;
            padding-right: 1rem;
            max-width: 180px;
            vertical-align: middle;
        }
    }

    .control {
        @include ms-icon('calendar', 20px, before);
        width: auto;
        flex: 1 1 auto;
        position: relative;

        &:before {
            position: absolute;
            top: 0;
            left: 10px;
            line-height: 38px;
        }

        &:after {
            top: 0;

            @include respond-to-up(md) {
                position: relative !important;
            }
        }

        input {
            flex: unset;
            margin-right: 10px;
            padding-left: 40px;

            @include respond-to-down(md, $breakpoints, true) {
                width: calc(100% - 20px);
            }
        }
    }
}

/* store switcher flag icon styles */
.flag-icon {
    display: inline-block;
    max-width: 16px;
    margin-right: .5rem;
}

.switcher-language .switcher-trigger,
.switcher-language .switcher-option a {
    display: flex;
}

html.ntrn__opened .switcher-language {
    margin: 0;

    .switcher-trigger {
        height: auto;
        max-width: none;
    }
}

/* fix width of the dropdown country picker issue seen with width set to 100% of parent on LGH-309 */
.switcher .options ul.dropdown {
    @include respond-to-up($tweakpoint-nav-toggle) {
        width: unset;
        min-width: 130px;
    }
}

/* fix alignment of the country text alongside country flags */
.switcher .options .switcher-trigger > strong,
.switcher .options ul.dropdown > .switcher-option > a > span {
    margin-top: 3px;
}
