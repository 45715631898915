@if $default-magento-nav {
    /* override styles only for $tweakpoint-nav-toggle */
    @include respond-to-up($tweakpoint-nav-toggle) {
        body {
            .navigation li.level1 {
                width: calc(100% / 5);
            }
            .navigation .submenu.level0 {
                flex-wrap: wrap;

                > .submenu > .level1 {
                    width: calc(100% / 5);
                }
            }

            .navigation .all-category {
                display: block;
                width: 100%;
                text-align: right;
                position: relative;
                margin-top: 20px;

                a {
                    display: inline-block;
                    width: auto;

                    &:before {
                        display: none;
                    }

                    &:after {
                        display: inline-block;
                        margin-left: .5rem;
                        font-family: $icon-font-family;
                        content: map-get($ms-icons, 'chevron-right');
                        line-height: 0;
                        vertical-align: top;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}
