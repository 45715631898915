.copyright {
  display: block;
  padding: 0 $copyright-padding $copyright-padding * 6;
  text-align: center;

  @include respond-to-up($footer-accordion-breakpoint) {
    padding-bottom: $copyright-padding;
  }

  > span {
    display: inline-block;
    padding: spacers(2);
  }
}
