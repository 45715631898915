// Top-Bar aka Header Panel (container for account links and compare items)
$header-panel-height: 39px;
$header-panel-bg: #F2F2F2;
$header-content-bg: #FFF;
$header-content-color: #000;

// Header Search input
$search-input-width: 610px;
$search-input-width-md: 610px;
$search-input-width-lg: 610px;
$search-input-height-sm: 46px;
$search-input-height-md: 46px;
$search-input-height-lg: 55px;
$search-input-bg: #FFF;
$search-input-border: 2px solid #444;

// Header Content (i,e container for logo, search and basket icon)
$header-content-min-height-sm:  44px;
$header-content-min-height-md:  100px;

// body font
$body-color: #000;

// Navigation
$navigation-item-bg-desktop: $black;

// USP Bar
$homepage-usp-height-desktop: 45px;
$homepage-usp-full-width:     true;  // To Make USP block span full-width of browser

// Homepage Slider
$homepage-slider-full-width: true;
$homepage-slider-height-mobile: 492px;
$homepage-slider-height-tablet: 415px;
$homepage-slider-height-desktop: 762px;

// Slick slider settings
$slick-arrow-bg: rgba($black, 0.4);
$slick-arrow-bg-radius: 50%;
$slick-arrow-color: rgba($white, 1);
$slick-dot-color: rgba($white, 0);
$slick-dot-color-active: rgba($white, 0.6);
$slider-content-shadow: none;
$slider-content-shadow-light: none;

// Category Layout - Sidebar
$category-layout-sidebar-width: 334px;
$category-layout-sidebar-width-lg: 284px;
$category-layout-sidebar-spacing-x: map_get($spacers, 3) * 1.125; // 18px

// Filters
$filter-block-padding-desktop: map_get($spacers, 3) * 1.875; // 30px
$filter-title-font-weight: normal;
$filter-title-text-transform: none;
$filter-title-letter-spacing: 0;
$filter-item-font-size: 13px;
$filter-item-padding: map_get($spacers, 1);
$filter-item-checkbox-spacing-x: $filter-item-padding * 2.5;
$filter-item-separator: 0 none;

// Toolbar
$toolbar-min-height: 60px;
$toolbar-font-size: 13px;
$toolbar-padding-x: map_get($spacers, 3);
$toolbar-padding-y: map_get($spacers, 3) * 0.5;
$toolbar-spacing-y: map_get($spacers, 4);
$toolbar-select-height: 40px;

// Product Grid - Items
$products-grid-items: (
    'xs': 2,
    'lg': 3,
    'xl': 4
);

// Product Item
$product-item-hover-reveal: false;
$product-item-bg: $white;
$product-item-padding-mobile: 14px;
$product-item-padding-desktop: map_get($spacers, 3);
$product-item-spacing-x-mobile: 3px;
$product-item-spacing-y-mobile: $product-item-spacing-x-mobile * 2;
$product-item-spacing-y: map_get($spacers, 3);

// Widgets
$promotional-block-spacing: 0;
$block-widget-dark-bg: #343a40;

// Login / registration / forgot password pages
$new-customers-brand-logo: '../../images/logo.svg';

// mobile nav toggle active state
$menu-active-background: $black;

// footer links
$footer-content-padding-mobile: 2rem;
$footer-content-padding-desktop: 4rem;

// max-width
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1010px,
    xxl: 1310px
);

$max-width: 1310px;

// fotorama
$fotorama-media-width: 500px;
$fotorama-media-height: 500px;

// minicart overrides
$block-minicart-color: $black;
$block-minicart-background: $white;

// basket page
$basket-item-bg: $white;
$basket-item-bg-border: 2px solid map-get($theme-colors, 'light');

// checkout
$shipping-address-item-bg: #eee;
$shipping-address-item-active-bg: map-get($theme-colors, 'primary');

// checkout progress step
$opc-progress-bar-active-bg: map-get($theme-colors, 'primary');
