%u-font-small {
  font-size: $font-size-sm;
}

%u-font-xsmall {
  font-size: $font-size-xs;
}

%u-heading-style {
  @if $headings-font-family != false {
    font-family: $headings-font-family;
  }
  font-weight: $headings-style-weight;
  letter-spacing: $headings-style-letter-spacing;
  text-transform: $headings-style-transform;
  line-height: $headings-line-height;
}

%u-headings-margin-bottom {
  margin-bottom: $headings-margin-bottom;
}

%u-heading-style-spl {
  @extend %u-heading-style;
  @extend %u-headings-margin-bottom;
  @include fluid-type('h4');
  font-family: $font-family-heading;
  line-height: $headings-line-height;
  letter-spacing: -0.02em;
}