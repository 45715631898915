// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.


//
// Color system
//

// stylelint-disable

// Monotones
$white:    #fff !default;
$gray-70:  #F5F7F6 !default;
$gray-100: #f8f9fa !default;
$gray-150: #eeeded !default;
$gray-200: #e9ecef !default;
$gray-300: #dddcdb !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-550: #bdbdbd !default;
$gray-600: #767676 !default;
$gray-625: #858585 !default;
$gray-650: #6c757d !default;
$gray-700: #4a4a4a !default;
$gray-800: #343a40 !default;
$gray-850: #333333 !default;
$gray-875: #2A2A2A !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: () !default;
$grays: map-merge((
        '70': $gray-70,
        '100': $gray-100,
        '150': $gray-150,
        '200': $gray-200,
        '300': $gray-300,
        '400': $gray-400,
        '500': $gray-500,
        '550': $gray-550,
        '600': $gray-600,
        '650': $gray-650,
        '700': $gray-700,
        '800': $gray-800,
        '850': $gray-850,
        '875': $gray-875,
        '900': $gray-900
), $grays);


// Colour Palette
// You can add more entries to the $colors map.
// To find name for Colour use 'Name that colour' (http://chir.ag/projects/name-that-color)

$colors: () !default;
$colors: map-merge((
        'green-leaf':   #2e6e0b,
        'snow-flurry':  #e0ffcf,
        'sunflower':    #ded31e,
        'cream':        #ffffcf,
        'milano-red':   #b70e0e,
        'your-pink':    #ffbebe,
        'picton-blue':  #1BA8E9,
        'pattens-blue': #def5ff
), $colors);


// Theme colours

$theme-colors: () !default;
$theme-colors: map-merge((
        'primary':    $black,
        'secondary':  $gray-600,
        'light':      $gray-150,
        'dark':       $gray-875,
        'positive':        map_get($colors, 'green-leaf'),
        'positive-subtle': map_get($colors, 'snow-flurry'),
        'caution':         map_get($colors, 'sunflower'),
        'caution-subtle':  map_get($colors, 'cream'),
        'negative':        map_get($colors, 'milano-red'),
        'negative-subtle': map_get($colors, 'your-pink'),
        'neutral':         map_get($colors, 'picton-blue'),
        'neutral-subtle':  map_get($colors, 'pattens-blue'),
        'screen-reader-bg': $gray-400,
        'backdrop': rgba($black, 0.58)
), $theme-colors);
// stylelint-enable

