// Flex utils placeholder
//

%u-flex-row-xcenter-ycenter {
  @include flex();
}


%u-flex-col-xcenter-ycenter {
  @include flex(column);
}